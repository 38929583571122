import { ThunkDispatch } from "redux-thunk";
import { strings } from "../../locales";
import { ENDPOINTS } from "../../utils/endpoints";
import { getErrorMessage, removeMaskElements } from "../../utils/helpers";
import { RootState } from "../rootReducer";
import { methodsServices } from "../services/methodsService";
import { SHOW_SNACK_BAR, SnackBarActionTypes } from "../snackBar/types";
import { message, messageActionsType } from "./models";
import { SEND_MSG_ATTEMPT, SEND_MSG_ERROR, SEND_MSG_SUCCESS } from "./types";

export const sendMessage = (data: message) => {
	return (
		dispatch: ThunkDispatch<
			RootState,
			undefined,
			messageActionsType | SnackBarActionTypes
		>
	) => {
		dispatch({
			type: SEND_MSG_ATTEMPT
		});
		methodsServices
			.post(
				ENDPOINTS.SENDMSG,
				{
					...data,
					phone_number: removeMaskElements(data.phone_number)
				},
				true
			)
			.then((res: any) => {
				dispatch({
					type: SEND_MSG_SUCCESS
				});
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: {
						message: strings("common.message_sent"),
						severity: "success"
					}
				});
			})
			.catch((error: any) => {
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: {
						message: getErrorMessage(error),
						severity: "error"
					}
				});
				dispatch({
					type: SEND_MSG_ERROR
				});
			});
	};
};
