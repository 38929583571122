import makeStyles from '@mui/styles/makeStyles';
import { ColorProps } from "../../interfaces/ColorsProps";

export const useStyles = makeStyles({
	divider: (props: ColorProps) => ({
		width: "100%",
		backgroundColor: props.colors[1],
		height: 2,
	}),
	container: {
		width: "100%",
		paddingTop: 30,
	},
	title: (props: ColorProps) => ({
		fontSize: 25,
		color: props.colors[1],
		fontFamily: "Avenir",
		margin: 0,
		marginBottom: 5,
	}),
	categoryTitle: {
		padding: "2.5%",
		paddingBottom: 20,
	},
});
