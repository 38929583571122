import {kioskChoiceState, kioskChoiceActionTypes} from "./models";
import {
    GET_MODES_ATTEMPT,
    GET_MODES_ERROR,
    GET_MODES_SUCCESS,
    CHANGE_KIOSK_MODE_STATUS_ATTEMPT,
    CHANGE_KIOSK_MODE_STATUS_ERROR,
    CHANGE_KIOSK_MODE_STATUS_SUCCESS
} from "./types";


const INITIAL_STATE = {
    modes: {
        loader: false,
        tables: [],
        globals: [],
        error: ""
    },
    changeStatus: {
        loader: false,
        error: ""
    }
};

export const kioskChoiceReducer = (
    state = INITIAL_STATE,
    action: kioskChoiceActionTypes
): kioskChoiceState => {
    switch (action.type) {
        case GET_MODES_ATTEMPT:
            return {...state, modes: {...state.modes, loader: true}};
        case GET_MODES_SUCCESS:
            return {
                ...state,
                modes: {
                    ...state.modes,
                    loader: false,
                    tables: action.tables,
                    globals: action.globals
                }
            };
        case GET_MODES_ERROR:
            return {...state, modes: {...state.modes, loader: false}};
        case CHANGE_KIOSK_MODE_STATUS_ATTEMPT:
            return {...state, changeStatus: {...state.changeStatus, loader: true}};
        case CHANGE_KIOSK_MODE_STATUS_SUCCESS:
            return {...state, changeStatus: {...state.changeStatus, loader: false}};
        case CHANGE_KIOSK_MODE_STATUS_ERROR:
            return {...state, changeStatus: {...state.changeStatus, loader: false}};
        default:
            return state;
    }
};