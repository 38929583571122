import React, { useCallback, useEffect, useRef, useState } from "react";
import BackgroundLayout from "../../layouts/BackgroundLayout";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { Input, Button, Spinner, RadioButton } from "../../components";
import { ChangeEvent } from "react";
import { changeStatus } from "../../store/kioskChoice/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import {
	button_type,
	LOCAL_STORAGE_MODE,
	SIGHTCORP_STATUS,
	SIGHT_CORP
} from "../../utils/constants";
import { KIOSK_MODES } from "../../utils/constants";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { config } from "../../config/config";
interface KioskChoiceProps {}

interface InputProps<T> {
	value: T;
	error: string | null;
}

export const KioskChoice = (props: KioskChoiceProps) => {
	const tableInputRef = useRef<HTMLInputElement | null>(null);
	const globalInputRef = useRef<HTMLInputElement | null>(null);
	const sightInputRef = useRef<HTMLInputElement | null>(null);

	const sightCorpStatus = parseInt(
		localStorage.getItem(SIGHTCORP_STATUS) as string
	);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [generalCode, setGeneralCode] = React.useState<InputProps<string>>({
		value: "",
		error: null
	});

	const [kioskMode, setKioskMode] = React.useState<any>({
		value: 0,
		error: null
	});

	const [checkedkioskMode, setCheckedkioskMode] = React.useState<any>(0);
	const [layout, setLayout] = useState("default");
	const keyboard = useRef<typeof Keyboard | null>(null);
	const ref = useRef<HTMLDivElement | null>(null);
	const [showKeyboard, setShowKeyboard] = useState(false);
	const wishInput = useRef<number>(-1);
	const [checkedkioskModeError, setCheckedkioskModeError] =
		React.useState<any>("");

	const [tableCode, setTableCode] = React.useState<InputProps<string>>({
		value: "",
		error: null
	});

	const [sightCorp, setSightCorp] = React.useState<InputProps<string>>({
		value: "",
		error: null
	});

	const handleOnSightCorpChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSightCorp({
			...sightCorp,
			value: e.target.value,
			error: null
		});
	};

	const handleOnGeneralCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
		setGeneralCode({
			...generalCode,
			value: e.target.value,
			error: null
		});
	};

	const handleOnTableCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
		setTableCode({
			...tableCode,
			value: e.target.value,
			error: null
		});
	};

	React.useEffect(() => {
		const kioskMode = localStorage.getItem(LOCAL_STORAGE_MODE);
		if (kioskMode) setKioskMode(parseInt(kioskMode));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (
			kioskModeSupportted &&
			parseInt(kioskModeSupportted) != KIOSK_MODES.GLOBAL_AND_TABLE &&
			parseInt(kioskModeSupportted) != KIOSK_MODES.INACTIVE
		) {
			setCheckedkioskMode(parseInt(kioskModeSupportted));
		}
	}, []);

	const kioskChoice = useSelector((state: RootState) => state.kioskChoice);
	const { modes: modesState, changeStatus: changeStatusState } = kioskChoice;
	const { loader: modesLoader, tables, globals } = modesState;
	const { loader: changeStatusLoader, error: changeStatusError } =
		changeStatusState;

	const changeGlobalModeStatus = () => {
		if (generalCode.value === "")
			setGeneralCode({
				...generalCode,
				error: t("validations.required")
			});
		if (generalCode.value !== "") {
			if (sightCorp.value !== "") {
				localStorage.setItem(SIGHT_CORP, sightCorp.value);
			} else {
				localStorage.setItem(
					SIGHT_CORP,
					config.DEEP_SIGHT_URL as string
				);
			}
			dispatch(changeStatus(generalCode.value, KIOSK_MODES.GLOBAL));
		}
	};

	const changeTableModeStatus = () => {
		if (tableCode.value === "")
			setTableCode({ ...tableCode, error: t("validations.required") });
		if (tableCode.value !== "") {
			if (sightCorp.value !== "") {
				localStorage.setItem(SIGHT_CORP, sightCorp.value);
			} else {
				localStorage.setItem(
					SIGHT_CORP,
					config.DEEP_SIGHT_URL as string
				);
			}
			dispatch(changeStatus(tableCode.value, KIOSK_MODES.TABLE));
		}
	};

	const clearError = () => {
		setGeneralCode({
			...generalCode,
			error: null
		});
		setTableCode({
			...tableCode,
			error: null
		});
	};

	const changeMode = () => {
		switch (checkedkioskMode) {
			case KIOSK_MODES.GLOBAL:
				changeGlobalModeStatus();
				if (sightCorp.value !== "") {
					localStorage.setItem(SIGHT_CORP, sightCorp.value);
				} else {
					localStorage.setItem(
						SIGHT_CORP,
						config.DEEP_SIGHT_URL as string
					);
				}
				break;
			case KIOSK_MODES.TABLE:
				changeTableModeStatus();
				if (sightCorp.value !== "") {
					localStorage.setItem(SIGHT_CORP, sightCorp.value);
				} else {
					localStorage.setItem(
						SIGHT_CORP,
						config.DEEP_SIGHT_URL as string
					);
				}
				break;
			default:
				break;
		}
	};
	const onChange = (input: any) => {
		switch (wishInput.current) {
			case 0:
				setGeneralCode({
					...generalCode,
					value: input,
					error: null
				});
				break;
			case 1:
				setTableCode({
					...tableCode,
					value: input,
					error: null
				});
				break;
			case 2:
				setSightCorp({
					...sightCorp,
					value: input,
					error: null
				});
				break;
			default:
				break;
		}
	};

	const onClick = () => {
		const kioskMode = localStorage.getItem(LOCAL_STORAGE_MODE);
		if (checkedkioskMode === 0) {
			setCheckedkioskModeError(t("errors.choose_kiosk_mode"));
		} else {
			setCheckedkioskModeError("");
		}
		clearError();
		if (kioskMode) {
			switch (parseInt(kioskMode)) {
				case KIOSK_MODES.GLOBAL:
					changeGlobalModeStatus();
					break;
				case KIOSK_MODES.TABLE:
					changeTableModeStatus();
					break;
				case KIOSK_MODES.GLOBAL_AND_TABLE:
					changeMode();
					break;
				default:
					break;
			}
		}
	};
	const onFocusGlobal = (e: React.FocusEvent<HTMLElement>) => {
		e.target.blur();
		setCheckedkioskMode(KIOSK_MODES.GLOBAL);
		setTableCode({
			...tableCode,
			value: "",
			error: null
		});

		if (keyboard.current !== null) {
			//@ts-ignore
			keyboard.current.setInput(generalCode.value);
		}
		wishInput.current = 0;
		setShowKeyboard(true);
	};

	const onFocusSightCorp = (e: React.FocusEvent<HTMLElement>) => {
		e.target.blur();
		setSightCorp({
			...sightCorp
		});
		if (keyboard.current !== null) {
			//@ts-ignore
			keyboard.current.setInput(sightCorp.value);
		}
		wishInput.current = 2;
		setShowKeyboard(true);
	};

	const kioskModeSupportted = localStorage.getItem(LOCAL_STORAGE_MODE) || "";

	const submitForm = (e: React.SyntheticEvent) => {
		e.preventDefault();
		onClick();
	};
	const onKeyPress = (button: any) => {
		/**
		 * If you want to handle the shift and caps lock buttons
		 */
		if (button === "{shift}" || button === "{lock}") handleShift();
	};

	const handleShift = () => {
		const newLayoutName =
			layout === button_type.DEFAULT
				? button_type.SHIFT
				: button_type.DEFAULT;
		setLayout(newLayoutName);
	};

	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			//@ts-ignore

			if (
				ref.current &&
				event.target.tagName.toUpperCase() !== "INPUT" &&
				!ref.current.contains(event.target)
			) {
				wishInput.current = -1;
				setShowKeyboard(false);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);

	return (
		<div>
			<div style={{ marginBottom: showKeyboard ? "30vh" : 0 }}>
				<BackgroundLayout>
					<form
						noValidate
						onSubmit={(e: React.SyntheticEvent) => {
							submitForm(e);
						}}
					>
						<p className="title">{t("kioskChoice.title")}</p>
						<p className="sub-title">
							{t("kioskChoice.sub_title")}
						</p>
						<div className="mode-container">
							<div className="radio-buttons">
								{(parseInt(kioskModeSupportted) ===
									KIOSK_MODES.GLOBAL ||
									parseInt(kioskModeSupportted) ===
										KIOSK_MODES.GLOBAL_AND_TABLE) && (
									<div className="col">
										<Input
											ref={globalInputRef}
											isFocus={
												wishInput.current === 0 &&
												checkedkioskMode ===
													KIOSK_MODES.GLOBAL
											}
											onFocus={(
												e: React.FocusEvent<HTMLElement>
											) => onFocusGlobal(e)}
											placeholder={t(
												"kioskChoice.placeholder"
											)}
											disabled={
												kioskMode &&
												KIOSK_MODES.GLOBAL === kioskMode
											}
											value={generalCode.value}
											onChange={handleOnGeneralCodeChange}
											type="default"
										/>
									</div>
								)}
								<div className="col">
									<div className="error-container"></div>
									<div className="error-container">
										<span className="error">
											{generalCode.error}
										</span>
									</div>
								</div>
								<div className="col">
									<div className="error-container"></div>
									<div className="error-container">
										<span className="error">
											{tableCode.error}
										</span>
									</div>
								</div>
							</div>
						</div>
						{sightCorpStatus ? (
							<div className="row">
								<p className="sub-title">
									{t("kioskChoice.sight_corp")}
								</p>
								<Input
									ref={sightInputRef}
									isFocus={wishInput.current === 2}
									onFocus={(
										e: React.FocusEvent<HTMLElement>
									) => onFocusSightCorp(e)}
									placeholder={
										config.DEEP_SIGHT_URL as string
									}
									value={sightCorp.value}
									onChange={handleOnSightCorpChange}
									type="default"
								/>
							</div>
						) : null}
						<p className="error">{t(changeStatusError)}</p>
						<p className="error">{checkedkioskModeError}</p>
						<div className="btn-container">
							<Button
								title={t("kioskChoice.activate_kiosk")}
								onClick={() => submitForm}
							/>
						</div>
						<Spinner loading={modesLoader || changeStatusLoader} />
					</form>
				</BackgroundLayout>
			</div>

			{showKeyboard && (
				<div ref={ref} className={"keyboardContainer"}>
					<Keyboard
						keyboardRef={(r: any) => (keyboard.current = r)}
						layoutName={layout}
						onChange={onChange}
						onKeyPress={onKeyPress}
					/>
				</div>
			)}
		</div>
	);
};
export default KioskChoice;
