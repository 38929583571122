import * as React from "react";
import "./styles.css";

interface ButtonProps {
	title: string;
	onClick?: () => void;
	disabled?: boolean;
}

export const Button = (props: ButtonProps) => {
	const { title, onClick, disabled } = props;
	return (
		<button type="submit" className="btn" onClick={onClick} disabled={disabled}>
			{title}
		</button>
	);
};
export default Button;
