import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ColorProps } from "../../interfaces/ColorsProps";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogTitleStyle: (props: ColorProps) => ({
			backgroundColor: props.colors[1],
			paddingBottom: 25
		}),
		cancelColor: (props: ColorProps) => ({
			color: props.colors[0],
			marginBottom: 5,
			fontWeight: "bold",
			marginTop: 5
		}),
		closePopup: (props: ColorProps) => ({
			color: props.colors[0],
			marginBottom: 5,
			fontWeight: "bold"
		}),
		inputStyle: (props: ColorProps) => ({
			backgroundColor: props.colors[0],
			borderColor: props.colors[1],
			borderRadius: 8,
			height: 50,
			width: "100%",
			paddingLeft: 8,
			color: props.colors[1]
		}),
		dialogActionStyle: (props: ColorProps) => ({
			backgroundColor: props.colors[0]
		}),
		btnStyle: (props: ColorProps) => ({
			color: props.colors[1],
			backgroundColor: props.colors[0],
			textTransform: "none"
		}),
		errorContainerStyle: (props: ColorProps) => ({
			marginTop: 5,
			marginBottom: 5,
			color: props.colors[0]
		}),
		radio: (props: ColorProps) => ({
			color: "black",
			"&$checked": {
				color: "black"
			}
		}),
		checked: (props: ColorProps) => ({
			color: "black"
		}),
		formControlLabelRoot: (props: ColorProps) => ({
			color: props.colors[0]
		}),
		input: (props: ColorProps) => ({
			borderRadius: "50px",
			borderColor: "transparent",
			color: " #6f6f6f !important",
			fontSize: "18px",
			width: "100%",
			lineHeight: "2rem",
			height: "50px",
			"& .MuiInputBase-input": {
				width: "50%",
				marginLeft: "20px",
				"::placeholder": {
					color: "blue"
				}
			}
		})
	})
);
