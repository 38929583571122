import React, { useState } from "react";
import {
	Box,
	Grid,
	ImageList,
	ImageListItem,
	Tab,
	Tabs,
	Typography
} from "@mui/material";
import {
	details,
	features,
	RelatedProducts
} from "../../utils/productDetailsFakeData";
import { config } from "../../config/config";
import CardMedia from "../CardMedia";
import Slider from "react-slick";
import { aboutThisProduct, LOCAL_STORAGE_CATEGORY, LOCAL_STORAGE_PARENT_CATEGORY, LOCAL_STORAGE_PRODUCT } from "../../utils/constants";
import { ShowChart } from "@mui/icons-material";
import { PATHS } from "../../routes/paths";
import { history } from "../../routes/history";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

interface ProductDetailsPanelProps {
	product: any;
	colors: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 4 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export const ProductDetailsPanel = (props: ProductDetailsPanelProps) => {
	const [value, setValue] = useState(0);
	const { product, colors } = props;
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};
	
	// TO DO
	const showDetails = (productItem) => {

  };
var settings = {
	dots: true,
	infinite: false,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	initialSlide: 0,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: true
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
};
	return (
		<Grid
			contaniner
			style={{
				display: "flex",
				flexDirection: "column",
				width: "80%"
			}}
		>
			<Grid item>
				<Box>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: "divider",
							width: "100%"
						}}
					>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="basic tabs example"
							// disable the tab indicator because it doesn't work well with wrapped container
							sx={{
								"& .MuiTabs-flexContainer": {
									flexWrap: "wrap",
									columnGap: "5%"
								}
							}}
						>
							<Tab label="ABOUT THIS PRODUCT" />
							<Tab label="FEATURES" />
							<Tab label="DETAILS & SPECS" />
							<Tab label="SHOW COMPLEMENTARY PRODUCTS" />
						</Tabs>
					</Box>
				</Box>
			</Grid>
			<Grid item>
				<TabPanel value={value} index={0}>
					<Typography>{aboutThisProduct}</Typography>
				</TabPanel>
				<TabPanel value={value} index={1}>
					{features.map((feature: string, index: number) => (
						<div style={{ textAlign: "left" }}>
							{feature}
							<br />
						</div>
					))}
				</TabPanel>
				<TabPanel value={value} index={2}>
					{details.map((detail: string, index: number) => (
						<div style={{ textAlign: "left" }}>
							{detail}
							<br />
						</div>
					))}
				</TabPanel>
				<TabPanel value={value} index={3}>
					<div>
						<Slider {...settings}>
							{product.related_products?.map((picture: any) => (
								<div key={picture.title}>
									<div
										style={{
											width: "100%",
											display: "flex",
											flexDirection: "column",
											alignItems: "center"
										}}
									>
										<CardMedia
											style={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
												objectFit: "contain",
												width: "200px",
												height: "200px"
											}}
											image={
												config.BASE_URL_API +
												picture.image
											}
											onclick={() => showDetails(picture)}
										/>

										<Typography
											style={{
												color: colors[1],
												paddingTop: "10px"
											}}
										>
											{picture.title}
										</Typography>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</TabPanel>
			</Grid>
		</Grid>
	);
};

export default ProductDetailsPanel;
