import React from "react";
import background from "../../assets/img/background.png";
import "./styles.scss";
import logo from "../../assets/img/logo.png";
import { Footer } from "../../components";

interface BackgroundProps {
	children: React.ReactNode;

}

export const Background = (props: BackgroundProps) => {
	const { children } = props;
	return (
		<div
			className="background"
			style={{ backgroundImage: `url(${background})` }}
		>
			<div className="container">
				<div className="box">
					<div className="logo">
						<img src={logo} alt="logo" />
					</div>
					<div className="child-container">{children}</div>
					<div className="full-footer">
						<Footer />
					</div>
				</div>
			</div>
		</div>
	);
};
export default Background;
