import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { Button, Input, Spinner } from "../../components";
import { ChangeEvent } from "react";
import { isEmail } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../store/authentification/actions";
import BackgroundLayout from "../../layouts/BackgroundLayout";
import "../../assets/sass/common.scss";
import { Typography } from "@mui/material";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { RootState } from "../../store/rootReducer";
import { button_type } from "../../utils/constants";
interface ResetPasswordProps {}

interface InputProps<T> {
	value: T;
	error: string | null;
}

export const ResetPassword = (props: ResetPasswordProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [layout, setLayout] = useState("default");
	const keyboard = useRef();
	const ref = useRef<HTMLDivElement | null>(null);
	const [showKeyboard, setShowKeyboard] = useState(false);
	const [wishInput, setWishInput] = useState(-1);

	const [email, setEmail] = React.useState<InputProps<string>>({
		value: "",
		error: null
	});

	const handleOnEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail({
			...email,
			value: e.target.value,
			error: null
		});
	};

	const onClick = () => {
		if (email.value === "")
			setEmail({ ...email, error: t("validations.required") });
		if (email.value !== "" && !isEmail(email.value))
			setEmail({ ...email, error: t("validations.email") });
		if (isEmail(email.value) && email.value !== "") {
			dispatch(
				resetPassword({
					email: email.value
				})
			);
		}
	};
	const handleShift = () => {
		const newLayoutName =
			layout === button_type.DEFAULT
				? button_type.SHIFT
				: button_type.DEFAULT;
		setLayout(newLayoutName);
	};
	const onKeyPress = (button: any) => {
		if (button === "{bksp}") {
			setEmail({ ...email, value: email.value.slice(0, -1) });
		}
		/**
		 * If you want to handle the shift and caps lock buttons
		 */
		if (button === "{shift}" || button === "{lock}") handleShift();
	};
	const onChange = (input: any) => {
		//@ts-ignore
		setEmail({ ...email, value: input });
	};
	const submitForm = (e: React.SyntheticEvent) => {
		e.preventDefault();
		onClick();
	};

	const resetPasswordState = useSelector(
		(state: RootState) => state.auth.resetPassword
	);
	const { loader } = resetPasswordState;
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			const element = event.target as HTMLElement;
			if (
				ref.current &&
				element &&
				!ref.current.contains(element) &&
				element.tagName &&
				element.tagName.toUpperCase() !== "INPUT" &&
				element.tagName.toUpperCase() !== "SPAN"
			) {
				setShowKeyboard(false);
				setWishInput(-1);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);
	const onFocusComment = (e: any) => {
		setWishInput(0);
		e.target.blur();

		if (keyboard.current !== undefined) {
			//@ts-ignore
			keyboard.current.setInput(email.value);
		}

		setShowKeyboard(true);
	};
	return (
		<div>
			<div style={{ marginBottom: showKeyboard ? "30vh" : 0 }}>
				<BackgroundLayout>
					<div style={{ marginBottom: 50 }}>
						<Typography style={{ fontSize: 18, color: "white" }}>
							{t("resetPassword.sendingEmail")}
						</Typography>
					</div>
					<form
						noValidate
						onSubmit={(e: React.SyntheticEvent) => {
							submitForm(e);
						}}
					>
						<div className="form-group ">
							<Input
								isFocus={wishInput === 0}
								onFocus={onFocusComment}
								type="email"
								placeholder={t("resetPassword.email")}
								value={email.value}
								onChange={handleOnEmailChange}
							/>
							<span className="error">{email.error}</span>
						</div>
						<div className="form-group ">
							<Button
								title={t("resetPassword.resetPassword")}
								onClick={onClick}
							/>
						</div>
					</form>
					<Spinner loading={loader} />
				</BackgroundLayout>
			</div>
			{showKeyboard && (
				<div ref={ref} className={"keyboardContainer"}>
					<Keyboard
						keyboardRef={(r: any) => (keyboard.current = r)}
						layoutName={layout}
						onChange={onChange}
						onKeyPress={onKeyPress}
					/>
				</div>
			)}
		</div>
	);
};
export default ResetPassword;
