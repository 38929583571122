import { AppBar, Grid } from "@mui/material";
import { MenuInterface, SubCategoryInterface } from "../../store/menu/types";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import { useStyles } from "./styles";
import { SUGGESTION_TAB_VALUE } from "../../utils/constants";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import React from "react";

interface AppBarSubCategoryProps {
	menu: Array<MenuInterface>;
	value: number;
	colors: Array<string>;
	handleChangeSubCategory: any;
	val: number;
	nameSubCategory: string;
	setNameSubCategory: any
}
interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Grid
					container
					justifyContent="space-around"
					alignItems="stretch"
				>
					{children}
				</Grid>
			)}
		</div>
	);
}


export const AppBarSubCategoryComponent = (
	props: AppBarSubCategoryProps
) => {
	const {
		menu,
		value,
		colors,
		val,
		handleChangeSubCategory,
	} = props;
	const classes = useStyles();
	const { t } = useTranslation();

  	interface StyledTabProps {
		label: string;
	}
	const StyledTab = withStyles((theme: Theme) =>
		createStyles({
			root: {
				paddingTop: 31,
				paddingBottom: 17,
				color: colors[1],
				fontWeight: "bold",
				fontSize: "11px",
				fontStyle: "italic",
				backgroundColor: colors[0],
				opacity: 1,
				borderBottom: " 6px solid " + colors[1],
				"&.Mui-selected": {
					backgroundColor: colors[0],
					color: colors[1],
					border: " 4px solid " + colors[1],
					borderBottom: "4px solid" + colors[0],
					padding: "2px"
				},
				[theme.breakpoints.up("md")]: {
					width: "70vw"
				}
			}
		})
	)((props: StyledTabProps) => <Tab disableRipple {...props} />);
	interface StyledTabsProps {
		value: number;
		onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
	}

	const StyledTabs = withStyles({
		indicator: {
			display: 'none',
		},
		scrollButtons: {
			backgroundColor: colors[0],
			color: colors[1],
			"& svg": {
				fontSize: "1.7rem"
			}
		},
		root: {
			backgroundColor: colors[0],
			width: "100%",
		},
		scroller: {
			flexGrow: 1
		}
	})((props: StyledTabsProps) => (
		<Tabs
			{...props}
			variant="scrollable"
			scrollButtons="auto"
			allowScrollButtonsMobile
		/>
	));

  return (
		<Grid className={classes.appBar}>
			{menu &&
				menu?.length > 0 &&
				menu.map((item: MenuInterface, index: number) => (
					<TabPanel
						key={"menu-panel-" + index + "-" + item.id}
						value={value}
						index={index}
					>
						<AppBar
							className={classes.appBar}
							style={{
								marginTop: "78px",
								opacity: "0.9",
								height: "65px"
							}}
							color="default"
							key={"menu-" + menu?.length}
							position="fixed"
						>
							{item.sub_categories &&
								item.sub_categories?.length > 0 && (
									<StyledTabs
										value={val}
										onChange={(_event, value) =>
											handleChangeSubCategory(value)
										}
									>
										{item.sub_categories.map(
											(
												subCategory: SubCategoryInterface,
												subCategoryIndex: number
											) =>
												subCategory.products?.length >
													0 && (
													<StyledTab
														label={
															item.id ===
															SUGGESTION_TAB_VALUE
																? t(
																		subCategory.name
																  )
																: subCategory.name
														}
														key={
															"menu-" +
															index +
															"-" +
															item.name +
															"-" +
															item.id
														}
													/>
												)
										)}
										{
											<div
												style={{
													borderBottom:
														" 6px solid " +
													colors[1],
													width:'100%'
												}}
											></div>
										}
									</StyledTabs>
								)}
						</AppBar>
					</TabPanel>
				))}
		</Grid>
  );
};
export default AppBarSubCategoryComponent;