import * as React from 'react';


interface SpinnerProps {
    loading: boolean;
}

export const Spinner = (props: SpinnerProps) => {
    const {loading} = props;
    return (
        <div style={{
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 999999,
            display: loading ? 'flex' : 'none',
            background: "rgba(108, 108, 108, 0.4)"
        }}>
            <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}
export default Spinner;