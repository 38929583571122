/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useRef } from "react";
import "../../assets/sass/common.scss";
import { Box, Button, Card,Container,Divider,Grid, Typography } from "@mui/material";
import placeHolder from "../../assets/img/placeholder.png";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import {
	IMAGE_WITH_PLAY_ICON,
	LINE_BREAK,
	LINE_BREAK_BALISE_HTML,
	LIST_IMAGES_FOUNDATION,
	LOCAL_STORAGE_CATEGORY,
	LOCAL_STORAGE_PARENT_CATEGORY,
	LOCAL_STORAGE_PRODUCT,
	NUMBER_REVIEWS,
	SEPHORA_IMAGES_BASE_URL,
	SEPHORA_PRODUCTS_IMAGES_BASE_URL,
	SUB_CATEGORY_FOUNDATION,
	VIDEO_SEPHORA
} from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { config } from "../../config/config";
import { history } from "../../routes/history";
import { PATHS } from "../../routes/paths";
import { TakeInOutDialog } from "../../components";
import { useDialog } from "../../hooks/useDialog";
import ConfirmDialog from "../../components/ConfirmDialog";
import ProductDetailsPanel from "../../components/productDetailsPanels/ProductDetailsPanel";
import Rating from "@mui/material/Rating";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import reviews from "../../../src/assets/img/ratings&Reviews.png";
import Slider from "react-slick";
import product1 from "../../../src/assets/img/product1.jpg";
import product2 from "../../../src/assets/img/product2.jpg";
import product3 from "../../../src/assets/img/product3.jpg";
import { common } from "../../locales/fr/common";
import productShippingMessages from '../../api/fakeData.json'
import ReactPlayer from "react-player";
import "./style.css";
interface ProductDetailsProps {}

export const ProductDetails = (props: ProductDetailsProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [value, setValue] = React.useState<number | null>(4);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const openImageViewer = useCallback(index => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

	const product = JSON.parse(
		localStorage.getItem(LOCAL_STORAGE_PRODUCT) || "{}"
	);
	const category = JSON.parse(
		localStorage.getItem(LOCAL_STORAGE_CATEGORY) || "{}"
	);
		const titleParentCategory = JSON.parse(
			localStorage.getItem(LOCAL_STORAGE_PARENT_CATEGORY) || "{}"
		);
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const dialogLogout = useDialog();

	const [quantity, setQunatity] = React.useState<number>(1);
	const [modifiersTotalPrice, setModifiersTotalPrice] =
		React.useState<number>(0);
	const [modifiers, setModifiers] = React.useState<Array<any>>([]);
	const [openDialog, setOpenDialog] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<string>("");
	const [array, setArrays] = React.useState<Array<string>>([]);
	const [alternateImages, setAlternateImages] = React.useState<Array<any>>(LIST_IMAGES_FOUNDATION);
	const [borderColor, setBorderColor] = React.useState<string>(colors[0]);
	  const [myStyle, setMyStyle] = useState<any>({});
	const closeDialog = () => {
		setOpenDialog(false);
	};
    const handleClick = (id: string) => {
		setMyStyle((prevState:string) => ({
			[id]: !prevState[id]
		}));
	};
const RelatedProducts = [
		product1,
	  product2,
	  product3,
  	config.BASE_URL_API + product.image,
];
	const handleSubmit = () => {
		const price = +(
			(quantity *
				(product.price +
					modifiers
						.map(item => item.price)
						.reduce((prev, curr) => prev + curr, 0))) /
			100
		).toFixed(2);
		const mods = modifiers.map(function (item) {
			return item.modifier_id;
		});
		dispatch(AddProductToShoopingCart(product, mods, price, quantity));
		dialogLogout.setOpen(false);
		history.push(PATHS.Home);
	};

	React.useEffect(() => {
		document.body.style.backgroundColor = colors[0];
	});

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const classes = useStyles({ colors: colors });

	const onBackBtnPress = () => {
		history.push(PATHS.Home);
	};
	var settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	return (
		<div>
			<AppBar
				position="fixed"
				style={{ backgroundColor: colors[1] }}
				className={classes.appBar}
			>
				<Toolbar variant="dense">
					<IconButton
						onClick={onBackBtnPress}
						edge="start"
						color="inherit"
						aria-label="menu"
						size="large"
					>
						<ArrowBackIos style={{ color: colors[0] }} />
					</IconButton>
					<Typography color="inherit">
						{category.name == common.name_suggestion ? (
							titleParentCategory
						) : (
							<>
								{titleParentCategory + " / "}

								<span
									className={classes.category}
									style={{ color: colors[0] }}
								>
									{category.name}
								</span>
							</>
						)}
					</Typography>
				</Toolbar>
			</AppBar>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className={classes.root}
			>
				{category.name == SUB_CATEGORY_FOUNDATION ? (
					<Grid item lg={4} md={5} sm={12} xs={10} container>
						<div className={classes.imgContainer}>
							<div className={classes.grid}>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "space-between"
									}}
								>
									{alternateImages &&
										alternateImages?.map((altImg: any) => (
											<button
												key={altImg?.image135}
												style={{
													border: "none",
													background: colors[0],
													display: "flex",
													flexDirection: "row"
												}}
												onClick={() => {
													setPreviewImage(
														SEPHORA_IMAGES_BASE_URL +
															altImg?.image135
													);
													setBorderColor(colors[1]);
													handleClick(
														altImg?.image135
													);
												}}
											>
												<img
													src={
														SEPHORA_IMAGES_BASE_URL +
														altImg?.image135
													}
													style={{
														borderRadius: "9999px",
														cursor: "pointer",
														width: "40%",
														borderColor:
															borderColor,
														border: myStyle[
															`${altImg?.image135}`
														]
															? "2px solid" +
															  colors[1]
															: ""
													}}
												></img>
											</button>
										))}
									{alternateImages &&
										<button
											key={IMAGE_WITH_PLAY_ICON}
											style={{
												border: "none",
												background: colors[0],
												display: "flex",
												flexDirection: "row"
											}}
											onClick={() => {
												setPreviewImage(
													IMAGE_WITH_PLAY_ICON
												);
												setBorderColor(colors[1]);
												handleClick(IMAGE_WITH_PLAY_ICON);
											}}
										>
											<img
												src={IMAGE_WITH_PLAY_ICON}
												style={{
													borderRadius: "9999px",
													cursor: "pointer",
													width: "60px",
													height: "60px",
													objectFit: "contain",
													borderColor: borderColor,
													border: myStyle[
														`${IMAGE_WITH_PLAY_ICON}`
													]
														? "2px solid" + colors[1]
														: "2px solid" + colors[0]
												}}
											></img>
										</button>
									}
								</div>
								{
									<Card
										style={{ width: "60%", height: "100%" }}
									>
										<img
											className={classes.media}
											src={
												previewImage ||
												(product.image &&
												!product.image.includes("https")
													? config.BASE_URL_API +
													  product.image
													: product.image &&
													  product.image.includes(
															"https"
													  )
													? product.image
													: placeHolder)
											}
											title={product.title}
											onClick={() => openImageViewer(0)}
										/>
									</Card>
								}
							</div>
							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
									backgroundColor: colors[0],
									marginTop: "5%"
								}}
							>
								{productShippingMessages.productShippingMessages.map(
									(product: any) => (
										<button
											key={product.skuId}
											style={{
												background: colors[0],
												border: myStyle[
													`${product.skuId}`
												]
													? "2px solid" + colors[1]
													: "2px solid" + colors[0],
												padding: "2px",
												borderRadius: "9999px"
											}}
											onClick={() => {
												setPreviewImage(
													SEPHORA_IMAGES_BASE_URL +
														product.skuImages
															.image135
												);
												setAlternateImages(
													product.alternateImages
												);
												handleClick(product.skuId);
											}}
										>
											<img
												src={
													SEPHORA_PRODUCTS_IMAGES_BASE_URL +
													product.skuId +
													"+sw.jpg"
												}
												style={{
													borderRadius: "9999px",
													cursor: "pointer"
												}}
											/>
										</button>
									)
								)}
							</div>
						</div>
					</Grid>
				) : (
					<Grid item lg={4} sm={12} xs={10} container>
						<div className={classes.card}>
							<Card>
								<img
									className={classes.img}
									src={
										previewImage ||
										(product.image &&
										!product.image.includes("https")
											? config.BASE_URL_API +
											  product.image
											: product.image &&
											  product.image.includes("https")
											? product.image
											: placeHolder)
									}
									title={product.title}
									//	onClick={() => openImageViewer(0)}
								/>
							</Card>
						</div>
					</Grid>
				)}

				<Grid
					lg={7}
					md={6}
					sm={12}
					xs={10}
					item
					className={classes.titleMainContainer}
				>
					<div className={classes.titleContainer}>
						<div className={classes.titleAndPrice}>
							<Grid item lg={12}>
								<div
									className={classes.title}
									style={{ color: colors[1] }}
								>
									{product.title}
								</div>
							</Grid>
						</div>
						<div>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-start",
									marginLeft: "1%",
									marginTop: "2%"
								}}
								onClick={handleOpen}
							>
								<Rating
									name="read-only"
									value={value}
									readOnly
									style={{ color: colors[1] }}
								/>
								<Typography className={classes.numberReviews}>
									{NUMBER_REVIEWS}
								</Typography>
							</div>

							<Modal open={open} onClose={handleClose}>
								<Box className={classes.box}>
									<div
										onClick={handleClose}
										className={classes.closeIcon}
									>
										<CloseIcon
											style={{
												border: "2px solid" + colors[1],
												borderRadius: "9999px",
												margin: "8px"
											}}
										/>
									</div>
									<Container
										style={{ height: "92%" }}
										className={classes.boxContent}
									>
										<img
											src={reviews}
											style={{ maxWidth: "100%" }}
										/>
									</Container>
								</Box>
							</Modal>
						</div>
						<div
							className={classes.descrption}
							dangerouslySetInnerHTML={{
								__html: product.description?.replaceAll(
									LINE_BREAK,
									LINE_BREAK_BALISE_HTML
								)
							}}
							style={{ color: colors[1] }}
						/>
						<div
							className={classes.price}
							style={{
								color: colors[1],
								display: "flex",
								flexDirection: "row",
								gap: "10px",
								marginTop: "20px"
							}}
						>
							<Button
								style={{
									backgroundColor: colors[1],
									color: colors[0]
								}}
								className={classes.btn}
								onClick={() => {
									dialogLogout.setOpen(true);
								}}
							>
								{t("productDetail.Receive_more_information")}
							</Button>
							<Button
								style={{
									backgroundColor: colors[1],
									color: colors[0]
								}}
								className={classes.btn}
								onClick={() => {
									setOpenDialog(true);
								}}
							>
								{t("productDetail.Talk_To_Sales_Person_Now")}
							</Button>
						</div>
					</div>
					<div className={classes.relatedP}>
						{RelatedProducts.map((relatedP, index) => {
							if (index !== 0) {
								return (
									<Card>
										<img
											src={relatedP}
											// onClick={() =>
											// 	setPreviewImage(relatedP)
											// }
											width="200"
											style={{
												height: "200px",
												objectFit: "contain"
											}}
											key={index}
											alt=""
										/>
									</Card>
								);
							}
						})}
					</div>
				</Grid>
				<Grid
					container
					sx={{ mt: "5%" }}
					className={classes.relatedProducts}
				>
					<div style={{}} className={classes.ImageChoice}>
						{isViewerOpen && (
							<Modal open={true} onClose={closeImageViewer}>
								<Box
									className={classes.box}
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "space-between"
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-around",
											height: "10%",
											marginTop: "1%"
										}}
									>
										<div
											style={{
												width: "95%",
												display: "flex",
												justifyContent: "center"
											}}
										>
											<Typography
												style={{
													color: colors[1],
													fontSize: "16px",
													fontWeight: "bold"
												}}
												component="h2"
											>
												SEPHORA COLLECTION Best Skin
												Ever Liquid Foundation
											</Typography>
										</div>
										<div
											onClick={closeImageViewer}
											style={{ width: "5%" }}
										>
											<CloseIcon
												style={{
													border:
														"2px solid" + colors[1],
													borderRadius: "9999px"
												}}
											/>
										</div>
									</div>
									<Container className={classes.boxContent}>
										<div
											style={{
												maxWidth: "80%",
												margin: "10%"
											}}
										>
											{
												<Slider {...settings}>
													<div
														style={{
															width: "100% !important",
															display: "flex",
															flexDirection:
																"column",
															alignItems:
																"center",
															border: "1px red solid",
															justifyContent:
																"center",
															marginLeft: "10%"
														}}
													>
														<ReactPlayer
															playing={true}
															controls
															url={VIDEO_SEPHORA}
															style={{
																alignItems:
																	"center",
																justifyContent:
																	"center",
																display: "flex",
																objectFit:
																	"contain",
																width: "300px !important",
																height: "300px",
																margin: "auto"
															}}
														/>
													</div>

													{alternateImages &&
														alternateImages?.map(
															(picture: any) => (
																<div
																	key={
																		picture.title
																	}
																>
																	<div
																		style={{
																			width: "100%",
																			display:
																				"flex",
																			flexDirection:
																				"column",
																			alignItems:
																				"center"
																		}}
																	>
																		<img
																			style={{
																				alignItems:
																					"center",
																				justifyContent:
																					"center",
																				display:
																					"flex",
																				objectFit:
																					"contain",
																				width: "300px",
																				height: "300px"
																			}}
																			src={
																				SEPHORA_IMAGES_BASE_URL +
																				picture?.image135
																			}
																		/>
																	</div>
																</div>
															)
														)}
												</Slider>
											}
										</div>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "center"
											}}
										>
											{alternateImages &&
												alternateImages?.map(
													(altImg: any) => (
														<button
															key={
																altImg?.image135
															}
															style={{
																border: "none",
																background:
																	colors[0]
															}}
															onClick={() => {
																setPreviewImage(
																	SEPHORA_IMAGES_BASE_URL +
																		altImg?.image135
																);
																setBorderColor(
																	colors[1]
																);
																handleClick(
																	altImg?.image135
																);
															}}
														>
															<img
																src={
																	SEPHORA_IMAGES_BASE_URL +
																	altImg?.image135
																}
																style={{
																	borderRadius:
																		"9999px",
																	cursor: "pointer",
																	width: "50%",
																	borderColor:
																		borderColor,
																	border: myStyle[
																		`${altImg?.image135}`
																	]
																		? "2px solid" +
																		  colors[1]
																		: ""
																}}
															></img>
														</button>
													)
												)}
										</div>
									</Container>
								</Box>
							</Modal>
						)}
					</div>
				</Grid>
				<Grid container className={classes.ProductDetailPanel}>
					<ProductDetailsPanel product={product} colors={colors} />
				</Grid>
			</Grid>
			<TakeInOutDialog
				open={dialogLogout.open}
				handleClose={() => {
					dialogLogout.setOpen(false);
				}}
				onBackdropClick={() => {
					dialogLogout.setOpen(true);
				}}
				handleSubmit={handleSubmit}
				productId={product.id}
			/>
			<ConfirmDialog
				handleClose={closeDialog}
				open={openDialog}
				message={message}
			/>
		</div>
	);
};
export default ProductDetails;
