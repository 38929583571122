import React, { useEffect } from "react";
import { AppBar, useScrollTrigger } from "@mui/material";
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { MenuInterface } from "../../store/menu/types";
import { Theme } from "@mui/material/styles";
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { SUGGESTION_TAB_VALUE } from "../../utils/constants";

interface AppBarProps {
	menu: Array<MenuInterface>;
	changeTab: (newValue: number) => void;
	value: number;
	colors: Array<string>;
}

interface Props {
	window?: () => Window;
	children: React.ReactElement;
}



export const AppBarComponent = (props: AppBarProps) => {
	const { t } = useTranslation();
	const { menu, colors, value, changeTab } = props;
	const classes = useStyles();
	interface StyledTabProps {
		label: string;
	}
	const StyledTab = withStyles((theme: Theme) =>
		createStyles({
			root: {
				paddingTop: 30,
				paddingBottom: 30,
				color: colors[0],
				fontWeight: "bold",
				fontSize: 15,
				backgroundColor: colors[1],
				opacity: 1,
				"&.Mui-selected": {
					backgroundColor: colors[0],
					color: colors[1]
				},
				[theme.breakpoints.up("md")]: {
					width: "70vw"
				}
			}
		})
	)((props: StyledTabProps) => <Tab disableRipple {...props} />);

	interface StyledTabsProps {
		value: number;
		onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
	}

	const StyledTabs = withStyles({

		indicator: {
			backgroundColor: colors[0],
			"& > span": {
				width: "100%",
				backgroundColor: colors[0]
			}
		},
		scrollButtons: {
			backgroundColor: colors[0],
			color: colors[1],
			"& svg": {
				fontSize: "1.7rem"
			}
		},
		root: {
			backgroundColor: colors[1],
			width: "100%"
		},
		scroller: {
			flexGrow: 1
		}
	})((props: StyledTabsProps) => (
		<Tabs
			{...props}
			variant="scrollable"
			scrollButtons="auto"
			allowScrollButtonsMobile
		/>
	));

	return (
		<AppBar
			className={classes.appBar}
			position="fixed"
			color="default"
			key={"menu-" + menu?.length}
		>
			<StyledTabs
				value={value}
				onChange={(_event, value) => changeTab(value)}
			>
				{menu &&
					menu?.length > 0 &&
					menu.map((item: MenuInterface, index: number) => (
						<StyledTab
							label={
								item.id === SUGGESTION_TAB_VALUE
									? t(item.name)
									: item.name
							}
							key={
								"menu-" +
								index +
								"-" +
								item.name +
								"-" +
								item.id
							}
						/>
					))}
			</StyledTabs>
		</AppBar>
	);
};
export default AppBarComponent;
