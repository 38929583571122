import React from "react";
import { CircularProgress, Grid } from "@mui/material";

export const Loader = React.memo(() => {
	return (
        <Grid
			container
			justifyContent="center"
			alignItems="center"
			className="global-loader"
		>
			<CircularProgress size={60} className="loader" />
		</Grid>
    );
});

export default Loader;
