import React from "react";
import {
	Button,
	Card,
	CardActionArea,
	CardContent,
	Grid,
	Typography
} from "@mui/material";
import { ProductInterface, CategoryInterface } from "../../store/menu/types";
import { config } from "../../config/config";
import placeHolder from "../../assets/img/placeholder.png";
import { useStyles } from "./styles";
import { RootState } from "../../store/rootReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
	LOCAL_STORAGE_PRODUCT,
	LOCAL_STORAGE_CATEGORY,
	LOCAL_STORAGE_PARENT_CATEGORY
} from "../../utils/constants";
import { PATHS } from "../../routes/paths";
import { history } from "../../routes/history";
import { getPriceFormat } from "../../utils/helpers";
import CardMedia from "../../components/CardMedia";

interface ProductProps {
	product: ProductInterface;
	category: CategoryInterface;
	title: string;
}

export const Product = (props: ProductProps) => {
	const { product, category, title } = props;
	const { t } = useTranslation();
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const classes = useStyles({ colors });

	const showDetails = () => {
		localStorage.setItem(LOCAL_STORAGE_PRODUCT, JSON.stringify(product));
		localStorage.setItem(LOCAL_STORAGE_CATEGORY, JSON.stringify(category));
		localStorage.setItem(LOCAL_STORAGE_PARENT_CATEGORY, JSON.stringify(title));
		history.push(PATHS.PRODUCT);
	};

	return (
		<Grid
			className={classes.product}
			container
			direction="column"
			alignItems="flex-end"
		>
			<Card className={classes.card} onClick={showDetails}>
				<CardActionArea>
					<CardMedia
						className={classes.media}
						image={
							product.image && !product.image.includes("https")
								? config.BASE_URL_API + product.image
								: product.image &&
								  product.image.includes("https")
								? product.image
								: placeHolder
						}
						title={product.title}
					/>
					<CardContent>
						<Grid
							container
							justifyContent="space-between"
							wrap="nowrap"
							className={classes.description}
						>
							<Typography className={classes.typography}>
								{product.title}
							</Typography>
						</Grid>
					</CardContent>
				</CardActionArea>
			</Card>
		</Grid>
	);
};
export default Product;
