export const ENDPOINTS = {
	MENU: "menu",
	LOGIN: "login",
	KIOSK_CHOICE: "kiosk/modes/makeAvailable",
	MODES: "kiosk/modes",
	LOGOUT: "logout",
	RESET_PASSWORD: "kiosk/password/email",
	SEND_LOG_MAIL: "logging",
	SUGGESTION: "recommended",
	SENDMSG: "catalog/products/information"
};
