import { message, messageActionsType, messageState } from "./models";
import { SEND_MSG_ATTEMPT, SEND_MSG_ERROR, SEND_MSG_SUCCESS } from "./types";

/***** Initial State */
const INITIAL_STATE: messageState = {
	msg: {} as message,
	loader: false,
	error: ""
};

export const messageReducer = (
	state = INITIAL_STATE,
	action: messageActionsType
): messageState => {
	switch (action.type) {
		case SEND_MSG_ATTEMPT:
			return { ...state, loader: true, error: "" };
		case SEND_MSG_SUCCESS:
			return { ...state, loader: false };
		case SEND_MSG_ERROR:
			return { ...state, loader: false };

		default:
			return state;
	}
};
