import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
const drawerWidth = "30%";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: `calc(100% - ${drawerWidth})`,
			marginRight: drawerWidth,
			[theme.breakpoints.down("md")]: {
				width: "100%",
				marginRight: 0
			}
		},
		footer: {
			position: "fixed",
			bottom: 0,
			width: `calc(100% - ${drawerWidth})`,
			[theme.breakpoints.down("md")]: {
				width: "100%"
			}
		},
		pageContainer: {
			marginBottom: 50
		},
		fullFooter: {
			width: "100%"
		}
	})
);
