import { Snackbar as SnackBarComponent } from "@mui/material";
import React, { useCallback } from "react";
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { hideSnackBar } from "../../../store/snackBar/actions";
import { useTranslation } from "react-i18next";
import { SQUARE_UP_STATUS } from "../../../utils/constants";

export const Snackbar = React.memo(() => {
	const squareStatus = parseInt(localStorage.getItem(SQUARE_UP_STATUS) as string);

	const { t } = useTranslation();

	// get redux snack bar state
	const snackBar = useSelector((state: RootState) => state.snackbar);
	// get dispatch hooks
	const dispatch = useDispatch();
	// handle close snack bar
	const handleClose = useCallback(() => {
		dispatch(hideSnackBar());
	}, [dispatch]);
	return (
		<SnackBarComponent
			open={snackBar.open}
			autoHideDuration={squareStatus?1000:5000}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
		>
			<MuiAlert
				elevation={6}
				variant="filled"
				onClose={handleClose}
				severity={snackBar.severity}
			>
				{t(snackBar.message)}
			</MuiAlert>
		</SnackBarComponent>
	);
});

export default Snackbar;