import React, { ComponentType } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { PATHS } from "./paths";
import { isLoggedIn, isConfigured } from "../utils/helpers";
import { useSelector } from "react-redux";
import { RootState } from "../store/rootReducer";
import { config } from "../config/config";
import { SQUARE_UP_STATUS } from "../utils/constants";

interface IProps extends RouteProps {
	component: ComponentType<any>;
	status?: number;
	path: string;
}

function ProtectedRoute(props: IProps): React.ReactElement {
	const { component: Component, ...rest } = props;
	const cardReaderState = useSelector((state: RootState) => state.cardreader);

	const render = (props: any) => {
		const squareStatus = parseInt(
			localStorage.getItem(SQUARE_UP_STATUS) as string
		);
		if (!isLoggedIn()) {
			return <Redirect to={PATHS.LOGIN} />;
		} else {
			if (!isConfigured()) {
				return <Redirect to={PATHS.KIOSK_CHOICE_PATH} />;
			}
		}

		return <Component {...props} />;
	};

	return <Route {...rest} render={render} />;
}

export default ProtectedRoute;
