import { ThunkDispatch } from "redux-thunk";
import {
	DeepSightActionTypes,
	DEEP_SIGHT,
	DEEP_SIGHT_FAIL,
	DEEP_SIGHT_SUCCESS,
	PersonDTO
} from "./types";
import { methodsServices } from "../services/methodsService";
import { config } from "../../config/config";
import { LOCAL_STORAGE_DEEP_SIGHT, SIGHT_CORP } from "../../utils/constants";
import { getPersonGender, sameAgeRange } from "../../utils/helpers";
import { RootState } from "../rootReducer";
import { getSuggestions } from "../menu/actions";
import { REMOVE_SUGGESTION_MENU, SuggestionsTypes } from "../menu/types";

/**
 * detect people
 *
 * @returns
 */

export const detectDeepSight = () => {
	return (
		dispatch: ThunkDispatch<
			RootState,
			undefined,
			DeepSightActionTypes | SuggestionsTypes
		>
	) => {
		const deepSightCorp= localStorage.getItem(SIGHT_CORP) as string;
		methodsServices
			.get("", false, deepSightCorp)
			.then((res: any) => {
				const prevPerson: PersonDTO = JSON.parse(
					localStorage.getItem(LOCAL_STORAGE_DEEP_SIGHT) || "{}"
				);
				if (
					res &&
					res?.data &&
					res?.data?.people &&
					res?.data?.people?.length > 0 &&
					res?.data?.people[0]
				) {
					const newPerson: PersonDTO = res?.data?.people[0];
					if (
						prevPerson &&
						prevPerson?.id &&
						(getPersonGender(newPerson.gender) === -1 ||
							newPerson.distance_from_camera >
								config.DEEP_SIGHT_DISTANCE)
					) {
						console.log(
							"deepSight success person is too far or has a wrong gender=>",
							newPerson.distance_from_camera
						);
						localStorage.removeItem(LOCAL_STORAGE_DEEP_SIGHT);
						dispatch({
							type: REMOVE_SUGGESTION_MENU
						});
					} else if (
						prevPerson?.id !== newPerson.id ||
						getPersonGender(newPerson.gender) !==
						getPersonGender(prevPerson.gender) ||
						!sameAgeRange(newPerson.age, prevPerson.age)
					) {
						localStorage.setItem(
							LOCAL_STORAGE_DEEP_SIGHT,
							JSON.stringify(newPerson)
						);
						console.log(
							"deepSight success new person data=",
							newPerson
						);
						dispatch({
							type: DEEP_SIGHT_SUCCESS,
							payload: { data: newPerson }
						});
						dispatch(
							getSuggestions(
								getPersonGender(newPerson.gender),
								newPerson?.age
							)
						);
					} else {
						console.log("deepSight success same person");
						
					}
				} else if (prevPerson && prevPerson?.id) {
					console.log("deepSight success no data", prevPerson);
					localStorage.removeItem(LOCAL_STORAGE_DEEP_SIGHT);
					dispatch({
						type: REMOVE_SUGGESTION_MENU
					});
				}
			})
			.catch((error: any) => {
				console.log("deepSight error =", error);
				dispatch({
					type: DEEP_SIGHT_FAIL,
					payload: {
						error: "error"
					}
				});
			});
	};
};
