import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ColorProps } from "../../interfaces/ColorsProps";
const drawerWidth = "30%";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			[theme.breakpoints.down('md')]: {
				width: "80%",
			},
		},
		drawerPaper: {
			width: drawerWidth,
			[theme.breakpoints.down('md')]: {
				width: "80%",
			},
		},
		fab: (props: ColorProps) => ({
			position: "fixed",
			right: 0,
			top: "50%",
			background: props.colors[1],
			color: props.colors[0],
			marginRight: 10,
			"&:hover": {
				background: props.colors[0],
				color: props.colors[1],
			},
		}),
		badge: (props: ColorProps) => ({
			"& .MuiBadge-badge": {
				background: props.colors[0],
				color: props.colors[1],
				border: "1px solid",
				borderColor: props.colors[1],
				top: "-7px !important",
				right: "-7px !important",
			},
		}),
	})
);
