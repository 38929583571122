import {
	LOGIN_ATTEMPT,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGOUT_ATTEMPT,
	LOGOUT_SUCCESS,
	LOGOUT_ERROR,
	RESET_PASSWORD_ATTEMPT,
	RESET_PASSWORD_ERROR,
	RESET_PASSWORD_SUCCESS
} from "./types";

import { AuthState, AuthActionTypes } from "./models";

const INITIAL_STATE: AuthState = {
	login: {
		loader: false,
		error: ""
	},
	logout: {
		loader: false
	},
	resetPassword: {
		loader: false
	}
};

export const authReducer = (
	state = INITIAL_STATE,
	action: AuthActionTypes
): AuthState => {
	switch (action.type) {
		case LOGIN_ATTEMPT:
			return {
				...state,
				login: { ...state.login, loader: true, error: "" }
			};
		case LOGIN_SUCCESS:
			return { ...state, login: { ...state.login, loader: false } };
		case LOGIN_ERROR:
			return { ...state, login: { ...state.login, loader: false } };
		case LOGOUT_ATTEMPT:
			return { ...state, logout: { ...state.logout, loader: true } };
		case LOGOUT_SUCCESS:
			return { ...state, logout: { ...state.logout, loader: false } };
		case LOGOUT_ERROR:
			return { ...state, logout: { ...state.logout, loader: false } };
		case RESET_PASSWORD_ATTEMPT:
			return {
				...state,
				resetPassword: { ...state.resetPassword, loader: true }
			};
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				resetPassword: { ...state.resetPassword, loader: false }
			};
		case RESET_PASSWORD_ERROR:
			return {
				...state,
				resetPassword: { ...state.resetPassword, loader: false }
			};
		default:
			return state;
	}
};
