import React, { useRef, useState, useEffect, ChangeEvent } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import Keyboard from "react-simple-keyboard";
import {
	Grid,
	IconButton,
	Typography,
	DialogContentText,
	Input
} from "@mui/material";
import { useStyles } from "./style";
import Button from "@mui/material/Button";
import "./styles.scss";
import { isEmail, removeMaskElements } from "../../utils/helpers";
import { sendMessage } from "../../store/message/actions";
import {
	button_type,
	input_choice,
	IS_FOCUSED_COLOR,
	PHONE_MASK,
	PHONE_MAX_CHARACTERS
} from "../../utils/constants";
import InputMask from "react-input-mask";
import Spinner from "../Spinner";

interface LogoutDialogProps {
	open: boolean;
	handleClose: () => void;
	onBackdropClick: () => void;
	handleSubmit: () => void;
	productId: number;
}

interface InputProps<T> {
	value: T;
	error: string | null;
}

const TakeInOutDialog: React.FC<LogoutDialogProps> = ({
	handleClose,
	open,
	onBackdropClick,
	productId
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const menuState = useSelector((state: RootState) => state.menu);
	const loading = useSelector(
		(state: RootState) => state.messageReducer.loader
	);
	const [layout, setLayout] = useState("default");
	const colors = menuState?.colors;
	const classes = useStyles({ colors });
	const keyboard = useRef();
	const ref = useRef<HTMLDivElement | null>(null);
	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const mailRef = useRef();
	const phoneNumberRef = useRef();
	const [showKeyboard, setShowKeyboard] = useState(false);
	const [wishInput, setWishInput] = useState(input_choice.click_Outsid);

	const [firstName, setFirstName] = React.useState<InputProps<string>>({
		value: "",
		error: null
	});
	const [lastName, setLastName] = React.useState<InputProps<string>>({
		value: "",
		error: null
	});
	const [email, setEmail] = React.useState<InputProps<string>>({
		value: "",
		error: null
	});
	const [phoneNumber, setPhoneNumber] = React.useState<InputProps<string>>({
		value: "",
		error: null
	});

	const handleOnPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPhoneNumber({
			...phoneNumber,
			value: e.target.value,
			error: null
		});
	};
	const clearInputs = () => {
		handleClose();
		setShowKeyboard(false);
		setFirstName({ ...firstName, value: "", error: "" });
		setLastName({ ...lastName, value: "", error: "" });
		setEmail({ ...email, value: "", error: "" });
		setPhoneNumber({ ...phoneNumber, value: "", error: "" });
	};

	const handleShift = () => {
		const newLayoutName =
			layout === button_type.DEFAULT
				? button_type.SHIFT
				: button_type.DEFAULT;
		setLayout(newLayoutName);
	};

	const onKeyPress = (button: any) => {
		if (button === button_type.backspace) {
			switch (wishInput.current) {
				case input_choice.firstName_Input:
					setFirstName({
						...firstName,
						value: firstName.value.slice(0, -1)
					});
					break;
				case input_choice.lastName_Input:
					setLastName({
						...lastName,
						value: lastName.value.slice(0, -1)
					});
					break;
				case input_choice.phoneNumber_Input:
					setPhoneNumber({
						...phoneNumber,
						value: phoneNumber.value.slice(0, -1)
					});
					break;
				default:
					break;
			}
		}
		if (button === button_type.SHIFT || button === button_type.LOCK)
			handleShift();
	};

	const onChange = (input: any) => {
		switch (wishInput) {
			case input_choice.firstName_Input:
				setFirstName({
					...firstName,
					value: input,
					error: null
				});
				break;
			case input_choice.lastName_Input:
				setLastName({
					...lastName,
					value: input,
					error: null
				});
				break;
			case input_choice.email_Input:
				setEmail({
					...email,
					value: input,
					error: null
				});
				break;
			case input_choice.phoneNumber_Input:
				let phoneNumbersOnly = input.replace(/\D/g, "");
				phoneNumbersOnly.length <= PHONE_MAX_CHARACTERS &&
					setPhoneNumber({
						...phoneNumber,
						value: phoneNumbersOnly,
						error: null
					});
				break;
			default:
				break;
		}
	};
	const onFocusFirstName = (e: any) => {
		e.target.blur();

		if (keyboard.current !== undefined) {
			//@ts-ignore
			keyboard.current.setInput(firstName.value);
		}
		setWishInput(input_choice.firstName_Input);
		setShowKeyboard(true);
	};
	const onFocusLastName = (e: any) => {
		e.target.blur();

		if (keyboard.current !== undefined) {
			//@ts-ignore
			keyboard.current.setInput(lastName.value);
		}
		setWishInput(input_choice.lastName_Input);
		setShowKeyboard(true);
	};

	const onFocusEmail = (e: any) => {
		e.target.blur();

		if (keyboard.current !== undefined) {
			//@ts-ignore
			keyboard.current.setInput(email.value);
		}
		setWishInput(input_choice.email_Input);
		setShowKeyboard(true);
	};
	const onFocusPhoneNumber = (e: any) => {
		e.target.blur();

		if (keyboard.current !== undefined) {
			//@ts-ignore
			keyboard.current.setInput(phoneNumber.value);
		}
		setWishInput(input_choice.phoneNumber_Input);
		setShowKeyboard(true);
	};

	const onClick = () => {
		if (email.value === "")
			setEmail({ ...email, error: t("validations.required") });
		if (email.value !== "" && !isEmail(email.value))
			setEmail({ ...email, error: t("validations.email") });
		if (firstName.value === "")
			setFirstName({ ...firstName, error: t("validations.required") });
		if (lastName.value === "")
			setLastName({
				...lastName,
				error: t("validations.required")
			});
		if (phoneNumber.value === "")
			setPhoneNumber({
				...phoneNumber,
				error: t("validations.required")
			});
		if (
			isEmail(email.value) &&
			email.value !== "" &&
			firstName.value !== "" &&
			lastName.value !== "" &&
			phoneNumber.value !== ""
		) {
			dispatch(
				sendMessage({
					first_name: firstName.value,
					last_name: lastName.value,
					email: email.value,
					phone_number: phoneNumber.value,
					product_id: productId
				})
			);
			clearInputs();
		}
	};
	const submitForm = (e: React.SyntheticEvent) => {
		e.preventDefault();
		onClick();
	};
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			//@ts-ignore
			if (
				ref.current &&
				!ref.current.contains(event.target) &&
				event.target.tagName.toUpperCase() !== "INPUT"
			) {
				setShowKeyboard(false);
				setWishInput(input_choice.click_Outsid);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);

	return (
		<div>
			<Dialog
				open={open}
				onClose={(event, reason) => {
					if (reason !== "backdropClick") {
						handleClose();
					}
				}}
				aria-labelledby="form-dialog-title"
				fullWidth
				onBackdropClick={onBackdropClick}
				style={{ marginBottom: showKeyboard ? 260 : 0 }}
			>
				<DialogTitle
					className={classes.dialogTitleStyle}
					id="form-dialog-title"
				>
					<Grid container justifyContent="center">
						<Grid item xs={11}>
							<Grid container justifyContent="center">
								<Typography
									className={classes.cancelColor}
									style={{ fontSize: 16 }}
								>
									{t("common.info_req_form")}
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={1}>
							<Grid container justifyContent="flex-end">
								<IconButton size="small" onClick={clearInputs}>
									<CancelIcon
										className={classes.closePopup}
									/>
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent className={classes.dialogTitleStyle}>
					<Grid display="flex" flexDirection="row" gap="38%">
						<DialogContentText className={classes.cancelColor}>
							{t("informations.first_name")}
						</DialogContentText>
						<DialogContentText className={classes.cancelColor}>
							{t("informations.last_name")}
						</DialogContentText>
					</Grid>

					<Grid display="flex" flexDirection="row" gap="20px">
						<Input
							style={{
								border:
									wishInput === input_choice.firstName_Input
										? `3px solid ${IS_FOCUSED_COLOR}`
										: undefined,
								backgroundColor: colors[0]
							}}
							className={classes.input}
							onFocus={onFocusFirstName}
							placeholder={t("informations.first_name")}
							type={"default"}
							ref={firstNameRef}
							value={firstName.value}
							onChange={onChange}
							disableUnderline={true}
						/>
						<Input
							style={{
								border:
									wishInput === input_choice.lastName_Input
										? `3px solid ${IS_FOCUSED_COLOR}`
										: undefined,
								backgroundColor: colors[0]
							}}
							disableUnderline={true}
							className={classes.input}
							onFocus={onFocusLastName}
							placeholder={t("informations.last_name")}
							type={"default"}
							ref={lastNameRef}
							value={lastName.value}
							onChange={onChange}
						/>
					</Grid>
					<Grid
						display="flex"
						flexDirection="row"
						justifyContent="space-around"
					>
						<span className="error">{firstName.error}</span>
						<span className="error">{lastName.error}</span>
					</Grid>
					<DialogContentText className={classes.cancelColor}>
						{t("informations.email")}
					</DialogContentText>
					<Input
						style={{
							border:
								wishInput === input_choice.email_Input
									? `3px solid ${IS_FOCUSED_COLOR}`
									: undefined,
							backgroundColor: colors[0]
						}}
						disableUnderline={true}
						className={classes.input}
						onFocus={onFocusEmail}
						placeholder={t("informations.email")}
						type={"email"}
						ref={mailRef}
						value={email.value}
						onChange={onChange}
					/>
					<span className="error">{email.error}</span>
					<DialogContentText className={classes.cancelColor}>
						{t("informations.phone_number")}
					</DialogContentText>

					<InputMask
						onFocus={onFocusPhoneNumber}
						mask={PHONE_MASK}
						value={phoneNumber.value}
						onChange={handleOnPhoneNumberChange}
					>
						{() => (
							<Input
								style={{
									border:
										wishInput ===
										input_choice.phoneNumber_Input
											? `3px solid ${IS_FOCUSED_COLOR}`
											: undefined,
									backgroundColor: colors[0]
								}}
								// type={"default"}
								ref={phoneNumberRef}
								value={phoneNumber.value}
								onChange={handleOnPhoneNumberChange}
								placeholder={t("informations.phone_number")}
								className={classes.input}
								disableUnderline={true}
							/>
						)}
					</InputMask>
					<span className="error">{phoneNumber.error}</span>
				</DialogContent>
				<DialogActions className={classes.dialogActionStyle}>
					<Button
						fullWidth
						className={classes.btnStyle}
						color="primary"
						onClick={submitForm}
					>
						<Typography style={{ fontSize: 16 }}>
							{t("common.submit")}
						</Typography>
						<Spinner loading={loading} />
					</Button>
				</DialogActions>
				{showKeyboard && (
					<div ref={ref} className={"keyboardContainer"}>
						<Keyboard
							keyboardRef={(r: any) => (keyboard.current = r)}
							layoutName={layout}
							onChange={onChange}
							onKeyPress={onKeyPress}
						/>
					</div>
				)}
			</Dialog>
		</div>
	);
};
export default React.memo(TakeInOutDialog);
