import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
	appBar: {
		width: "100vw",
		left: 0,
		[theme.breakpoints.down("md")]: {
			width: "100%"
		}
	}
}));
