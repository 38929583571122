import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import logo from "../../assets/img/tyme2.png";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";

interface PowredByTymeProps {}

export const PowredByTyme = (props: PowredByTymeProps) => {
	const { t } = useTranslation();
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const classes = useStyles({ colors });

	return (
        <Grid container justifyContent="center" alignItems="center">
			<Typography className={classes.typography}>
				{t("common.powered")}
			</Typography>
			<img src={logo} alt="logo" className={classes.logo} />
		</Grid>
    );
};
export default PowredByTyme;
