import { combineReducers } from "redux";
import { authReducer } from "./authentification/reducer";
import { menuReducer } from "./menu/reducer";
import { snackBarReducer } from "./snackBar/reducer";
import { kioskChoiceReducer } from "./kioskChoice/reducer";
import { messageReducer } from "./message/reducer";
import { deepSightReducer } from "./deepSight/reducer";

export const rootReducer = combineReducers({
	auth: authReducer,
	menu: menuReducer,
	snackbar: snackBarReducer,
	kioskChoice: kioskChoiceReducer,
	messageReducer: messageReducer,
	deepSight: deepSightReducer
});

export type RootState = ReturnType<typeof rootReducer>;
