export const error = {
	menu: {
		423: "Global merchant not found"
	},
	network: "Please check your internet connection !",
	unknow: "Server error",
	checkout: {
		404: "Order not found",
		417: "order has been cancelled",
		423: "Wrong mode id"
	}
};
