import React, { FunctionComponent } from "react";

export interface MainProps {}

export const PlaceIcon: FunctionComponent<MainProps> = () => {
	return (
		<svg
			id="Capa_1"
			enableBackground="new 0 0 512 512"
			height="512"
			viewBox="0 0 512 512"
			width="512"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
				<path d="m71.629 80.16c-4.142 0-7.5 3.358-7.5 7.5v88.177c0 4.705-3.828 8.532-8.532 8.532-4.142 0-7.5 3.358-7.5 7.5v216.436c0 4.705-3.828 8.532-8.532 8.532s-8.532-3.828-8.532-8.532v-216.436c0-4.142-3.358-7.5-7.5-7.5-4.705 0-8.532-3.828-8.532-8.532v-88.177c0-4.142-3.358-7.5-7.5-7.5s-7.501 3.358-7.501 7.5v88.177c0 10.354 6.723 19.168 16.032 22.307v210.161c0 12.976 10.557 23.532 23.532 23.532s23.532-10.557 23.532-23.532v-210.161c9.309-3.139 16.032-11.953 16.032-22.307v-88.177c.001-4.142-3.357-7.5-7.499-7.5z" />
				<path d="m39.564 167.305c4.142 0 7.5-3.358 7.5-7.5v-72.145c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v72.145c0 4.142 3.358 7.5 7.5 7.5z" />
				<path d="m504.5 80.16c-30.656 0-55.597 24.941-55.597 55.597v152.306c0 4.142 3.358 7.5 7.5 7.5h40.597v112.742c0 4.705-3.828 8.532-8.532 8.532s-8.532-3.828-8.532-8.532v-88.177c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v88.177c0 12.976 10.557 23.532 23.532 23.532s23.532-10.556 23.532-23.532v-320.645c0-4.142-3.358-7.5-7.5-7.5zm-40.597 55.596c0-19.823 14.282-36.374 33.097-39.902v184.709h-33.097z" />
				<path d="m352.362 215.632c-3.924 1.326-6.03 5.583-4.704 9.507 3.352 9.917 5.051 20.299 5.051 30.86 0 53.326-43.384 96.71-96.709 96.71s-96.709-43.384-96.709-96.71c0-44.736 30.308-83.373 73.704-93.958 4.024-.982 6.491-5.04 5.509-9.064-.981-4.024-5.04-6.49-9.063-5.509-50.135 12.23-85.149 56.859-85.149 108.531 0 61.597 50.113 111.71 111.709 111.71s111.709-50.113 111.709-111.71c0-12.197-1.965-24.196-5.841-35.663-1.327-3.925-5.584-6.029-9.507-4.704z" />
				<path d="m410.919 173.144c-3.775-7.025-12.466-9.817-19.787-6.354-2.169 1.026-4.482 1.546-6.874 1.546-4.079 0-8.031-1.564-11.129-4.405-2.123-1.948-5.174-2.507-7.851-1.437-2.676 1.069-4.503 3.577-4.7 6.451-.593 8.648-7.797 15.423-16.401 15.423-6.85 0-13.061-4.364-15.456-10.86-.902-2.447-3.01-4.25-5.568-4.761-2.56-.51-5.197.344-6.969 2.256-3.515 3.791-8.443 5.718-13.524 5.287-5.801-.492-11.229-4.664-13.828-10.628-1.629-3.739-1.698-7.383-.214-11.468.864-2.379.47-5.032-1.048-7.057s-3.958-3.144-6.479-2.987c-.403.026-.799.074-1.194.129l-.059.008c-9.028-.113-16.338-7.492-16.338-16.547 0-4.597 1.899-8.89 5.349-12.087 1.913-1.773 2.767-4.412 2.256-6.97s-2.313-4.666-4.761-5.568c-4.869-1.795-8.66-5.801-10.139-10.717-2.199-7.307-8.75-11.967-15.94-11.325-45.563 4.052-87.202 25.361-117.247 60.002-30.385 35.034-45.473 79.962-42.483 126.51 2.728 42.489 21.193 82.674 51.993 113.154 30.795 30.475 71.172 48.527 113.692 50.832 3.311.179 6.61.269 9.896.269 45.213 0 87.785-16.901 120.79-48.165 35.425-33.556 54.934-78.9 54.934-127.677-.001-28.736-7.235-57.387-20.921-82.854zm-44.33 199.642c-32.381 30.674-74.838 46.232-119.558 43.808-38.851-2.106-75.769-18.626-103.953-46.516-28.187-27.893-45.083-64.634-47.576-103.453-2.734-42.587 11.062-83.684 38.847-115.72 27.453-31.654 65.48-51.138 107.096-54.876.112.104.29.34.396.693 1.87 6.215 5.659 11.666 10.69 15.633-2.634 4.645-4.031 9.896-4.031 15.386 0 14.826 10.28 27.297 24.087 30.657-.222 4.503.61 8.937 2.494 13.259 4.769 10.944 15.097 18.63 26.311 19.582 6.392.544 12.647-.855 18.082-3.927 5.879 7.454 14.948 12.058 24.704 12.058 12.505 0 23.464-7.457 28.502-18.27 3.662 1.468 7.583 2.238 11.579 2.238 4.627 0 9.098-1.005 13.288-2.987.085-.04.158-.038.2-.032 12.669 23.604 19.093 49.065 19.093 75.68-.001 44.616-17.847 86.091-50.251 116.787z" />
			</g>
		</svg>
	);
};

export default React.memo(PlaceIcon);
