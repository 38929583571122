import * as React from "react";
import { ChangeEvent } from "react";
import { IS_FOCUSED_COLOR } from "../../utils/constants";
import "./styles.css";

interface InputProps {
	placeholder: string;
	type: "email" | "password" | "default";
	value: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	readOnly?: boolean;
	ref?: any;
	onFocus?: any;
	isFocus: boolean;
	color?: string;
}

export const Input = React.forwardRef((props: InputProps) => {
	const {
		placeholder,
		type,
		value,
		onChange,
		disabled,
		readOnly,
		ref,
		onFocus,
		isFocus = true
	} = props;
	return (
		<input
			style={{
				border: isFocus ? `3px solid ${IS_FOCUSED_COLOR}` : undefined
			}}
			onFocus={onFocus}
			ref={ref}
			readOnly={readOnly}
			className="container"
			type={type}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			disabled={disabled ? disabled : false}
		/>
	);
});
export default Input;
