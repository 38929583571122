import makeStyles from '@mui/styles/makeStyles';
import { ColorProps } from "../../interfaces/ColorsProps";

export const useStyles = makeStyles({
    logo: {
        height: 30,

    },
    typography: (props: ColorProps) => ({
        fontfamily: "Roboto",
        fontWeight: 500,
        letterSpacing: 5,
        textTransform: "uppercase",
        paddingRight: 10,
        margin: 0,
        fontSize: 12,
        color: props.colors[0]
    }),
});
