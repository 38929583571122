import product1 from "../../src/assets/img/product1.jpg";
import product2 from "../../src/assets/img/product2.jpg";
import product3 from "../../src/assets/img/product3.jpg";

export const features = [
	"Sephora believes in championing all beauty, living with courage, and standing fearlessly together to celebrate our differences. Even if we stumble, we will never stop building a community where diversity is expected, self-expression is honored, all are welcomed, and you are included. This mission informs our brand platform, “We Belong to Something Beautiful,” which is our guiding light and motivates all we do."
];
export const details = [
	" We’re committed to supporting equity, justice, and inclusion within our communities through charitable giving initiatives and strategic partnerships. Our giving strategy combines Sephora-funded donations with a deeper collaboration with nonprofits. Each month, we feature key nonprofits within our marketing channels, building awareness for their efforts to drive equity and inclusion, and inviting clients to join us. Clients may contribute by redeeming their Beauty Insider points as a donation and have redeemed more than 150 million points as donations since this program’s launch in 2020."
];
export const RelatedProducts = [
	{
		src: product1,
		text: "text1"
	},
	{
		src: product2,
		text: "text1"
	},
	{
		src: product3,
		text: "text1"
	}
];
