export const errors = {
	invalid_credentials: "Please verify your address mail and your password",
	no_internet: "Please check your internet connection",
	merchant_disabled: "this merchant was disabled",
	merchant_pending: "this merchant is pending",
	global_disabled_removed:
		"Sorry, the activation code you have entered does not exist",
	table_or_global_not_found: "Table or global merchant not found",
	table_or_global_disabled_removed:
		"Table or global merchant disabled or removed",
	choose_kiosk_mode: "Please choose your kiosk mode",
	menu: {
		423: "Global merchant not found"
	}
};
