import React from "react";
import { Grid } from "@mui/material";
import {
	MenuInterface, ProductInterface
} from "../../store/menu/types";
import { Product, SubCategory } from "..";
import { useStyles } from "./styles";
import { common } from "../../locales/fr/common";

interface TabPanelComponentProps {
	menu: Array<MenuInterface>;
	value: number;
	subCateg: number;
	colors: Array<string>;
}

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: number;
	subCateg: number;
}
function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Grid
					container
					justifyContent="space-around"
					alignItems="stretch"
				>
					{children}
				</Grid>
			)}
		</div>
	);
}

export const TabPanelComponent = (props: TabPanelComponentProps) => {
	const { menu, value, subCateg } = props;
	const item = menu[value];
	const classes = useStyles();

	return (
		<Grid className={classes.container}>
			{menu &&
				menu?.length > 0 &&
				menu.map((item: MenuInterface, index: number) => (
					<TabPanel
						key={"menu-panel-" + index + "-" + item.id}
						value={value}
						index={index}
					>
						{item?.name == common.name_suggestion &&
							item?.products &&
							item?.products?.length > 0 &&
							item?.products.map(
								(
									product: ProductInterface,
									productIndex: number
								) => (
									<Product
										category={item}
										product={product}
										key={
											"product-" +
											productIndex +
											"-" +
											product.title +
											"-" +
											product.id
										}
										title={item.name}
									/>
								)
							)}
						{item.sub_categories[subCateg]?.products?.length >
							0 && (
							<SubCategory
								category={item.sub_categories[subCateg]}
								title={item.name}
								
								//	key={"categort-" + item.sub_categories[value]}
							/>
						)}
					</TabPanel>
				))}
		</Grid>
	);
};
export default TabPanelComponent;
