import { kioskChoice } from "./kioskChoice";
import { login } from "./login";
import { validations } from "./validations";
import { errors } from "./errors";
import { error } from "./error";
import { common } from "./common";
import { productDetail } from "./productDetails";
import { resetPassword } from "./resetPassword";
import { informations } from "./informations";

const fr = {
	login,
	validations,
	errors,
	error,
	common,
	kioskChoice,
	productDetail,
	resetPassword,
	informations
};

export default fr;
