import React, { useEffect } from "react";
import "./App.css";
import { Router } from "react-router";
import { history } from "./routes/history";
import { SnackBar } from "./components/ui";
import { Routes } from "./routes";
import "./assets/sass/common.scss";
import { LOCAL_STORAGE_DEEP_SIGHT } from "./utils/constants";
import {
	ThemeProvider,
	Theme,
	StyledEngineProvider,
	createTheme
} from "@mui/material/styles";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

declare module "@mui/styles/defaultTheme" {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const theme = createTheme();

function App() {
	useEffect(() => {
		localStorage.removeItem(LOCAL_STORAGE_DEEP_SIGHT);
	}, []);
	const { t } = useTranslation();

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<div className="App">
					<Helmet>
						<meta charSet="utf-8" />
						<title>{t("common.application_title")}</title>
					</Helmet>
					<SnackBar />
					<Router history={history}>
						<Routes />
					</Router>
				</div>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
