// storage keys
export const SQUARE_CHECKOUT_ID = "squareCheckoutId";
export const SQUARE_UP_STATUS = "squareUpStatus";
export const SIGHTCORP_STATUS = "sightcorp_status";
export const LOCAL_STORAGE_TOKEN = "token";
export const LOCAL_STORAGE_MODE = "modeId";
export const LOCAL_STORAGE_ORDER_ID = "orderId";
export const LOCAL_STORAGE_LOCATION_ID = "locationId";
export const LOCAL_STORAGE_SELECTED_MODE = "selectedMode";
export const LOCAL_STORAGE_SELECTED_ORDER_TYPE = "orderType";
export const LOCAL_STORAGE_PAYMENT_INTENT_ID = "paymentIntentId";
export const LOCAL_STORAGE_WAITING_TIME = "waitingTime";
export const LOCAL_STORAGE_SQUARE_WAITING_TIME = "waiting_time";
export const LOCAL_STORAGE_PRODUCT = "product";
export const LOCAL_STORAGE_SERIAL_NUMBER = "serialNumber";
export const LOCAL_STORAGE_CATEGORY = "category";
export const LOCAL_STORAGE_PARENT_CATEGORY = 'title';
export const LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED = "kioskChoiceConfigured";
export const SEPHORA_IMAGES_BASE_URL = "https://www.sephora.com/";
export const SEPHORA_PRODUCTS_IMAGES_BASE_URL = "https://www.sephora.com/productimages/sku/s"; 
export const VIDEO_SEPHORA = "https://www.youtube.com/watch?v=PvPzh14Lzj4";
export const IMAGE_WITH_PLAY_ICON = "https://tyme.web2.anypli.com/images/sephora2.png";
export const SUB_CATEGORY_FOUNDATION = "Face / Foundation";
export const LIST_IMAGES_FOUNDATION = [
	{ image135: "/productimages/sku/s2327179-av-01-grid.jpg" },
	{ image135: "/productimages/sku/s2327179-av-08-grid.jpg" },
	{ image135: "/productimages/sku/s2327179-av-10-grid.jpg" },
	{ image135: "/productimages/sku/s2327179-av-20-grid.jpg" }
];
export const DEFAULT_PRIMARY_COLOR = "#CFE2F3";
export const DEFAULT_SECONDARY_COLOR = "#C90076";
export const DEFAULT_LABEL_COLOR_SLIDER = "#FFE3AA";
export const UNAUTHORIZED_STATUS = 423;
export const MODEL_NOT_FOUND = 404;
export const UNAUTHORIZED_TOKEN = 401;

export const AXIOS_TIMEOUT = 10000;
export const NUMBER_REVIEWS = "1K";
export const IS_FOCUSED_COLOR = "#363287";
export const LINE_BREAK = '\r\n';
export const LINE_BREAK_BALISE_HTML = '<br/>';
export const KIOSK_MODES = {
	INACTIVE: 0,
	GLOBAL: 1,
	TABLE: 2,
	GLOBAL_AND_TABLE: 3
};

export const KIOSK_MODE_AVAILABILITY = {
	NOT_AVAILABILE: 0,
	AVAILABILE: 1
};
export const PAIR = {
	SEPARATED: 0,
	PAIRED: 1
};

export const KIOSK_MODE_STATUS = {
	INACTIVE: 0,
	ACTIVE: 1
};

export const PHONE_MAX_CHARACTERS = 15;

export const MIN_PASSWORD_LENGTH = 6;
export const PHONE_MASK = "999-999-999-999-999";
export const NUMBER_OF_SECONDS_OF_COUNTER = 5;
export const NUMBER_OF_CLICKS_TO_RELOAD_PAGE = 5;
export const NUMBER_OF_SECONDS_TO_REDIRECT_TO_HOME = 10000;

export const SIGHT_CORP = "sight_corp";
export const ORDER_IN_Values = {
	IN: "1",
	OUT: "0"
};
export const input_choice = {
	click_Outsid: -1,
	firstName_Input: 0,
	lastName_Input: 1,
	email_Input: 2,
	phoneNumber_Input: 3,
	password_Input: 4
};

export const button_type = {
	DEFAULT: "default",
	SHIFT: "shift",
	LOCK: "lock",
	backspace: "bksp"
};
export const removeElevation = 0;
export const addElevation = 4;
export const USE_TAX_VALUE = 0;
export const NUMBER_OF_SECONDS_TO_SHOW_SLIDER = 120;
export const NUMBER_OF_SECONDS_TO_SHOW_SLIDER_IN_CHECKOUT_PAGE = 180;

export const NO_READER_STATUS = "no_established_connection";
export const NUMBER_OF_CLICKS_TO_FORCE_LOGOUT = 5;

export const RETRY_READER_CONNECT_COUNT = 1440;
export const TIME_OUT_RECONNECT = 60000;

export const DEEP_SIGHT_DEFAULT_TIMER = "60000";

export const LOCAL_STORAGE_DEEP_SIGHT = "deep_sight";

export const DEEP_SIGHT_DEFAULT_DISTANCE = "1";

export const SUGGESTION_TAB_VALUE = 0;

export const MALE_GENDER = 1;
export const FEMALE_GENDER = 2;

export const DEEP_ENV_VALUE = "1";

export const DEFAULT_TIP_PERCENTAGE = 20;

export const aboutThisProduct =
	"Cosmetic products must not only promote healthy skin but also provide a beautiful tapestry of colors for every complexion. The state of the art in classical cosmetics is the use of finely crushed minerals and botanicals of the best quality. ";
export const SUGGESTION_CATEGORY = 'suggestions';	