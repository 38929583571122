export const DEEP_SIGHT = "deepSight/detect";
export const DEEP_SIGHT_SUCCESS = "deepSight/success";
export const DEEP_SIGHT_FAIL = "deepSight/fail";

interface DeepSightction {
	type: typeof DEEP_SIGHT;
}

export interface PersonDTO {
	id: number;
	age: number;
	gender: number;
	distance_from_camera: number;
}
export interface DeepSightState {
	detecting: boolean;
	person: PersonDTO | null;
}

interface DeepSightErrorAction {
	type: typeof DEEP_SIGHT_FAIL;
	payload: {
		error: string;
	};
}

interface DeepSightSuccessAction {
	type: typeof DEEP_SIGHT_SUCCESS;
	payload: {
		data: PersonDTO | null;
	};
}
export type DeepSightActionTypes =
	| DeepSightction
	| DeepSightErrorAction
	| DeepSightSuccessAction;
