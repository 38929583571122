import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { ProductInterface, SubCategoryInterface } from "../../store/menu/types";
import { useStyles } from "./styles";
import { RootState } from "../../store/rootReducer";
import { useSelector } from "react-redux";
import { Product } from "..";

interface SubCategoryProps {
	category: any;
	title: string;
}

export const SubCategory = (props: SubCategoryProps) => {
	const { category, title } = props;

	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;

	const classes = useStyles({ colors });
	return (
        <Grid container className={classes.container} justifyContent="space-around">
			<Grid container className={classes.categoryTitle}>
			</Grid>

			   {category?.products.map(
				(product: ProductInterface, productIndex: number) => (
						 <Product
							 title={title}
						category={category}
						product={product}
						key={
							"category-" +
							category.id +
							"product-" +
							productIndex +
							product.title +
							"-" +
							product.id
						}
					/>
				)
			)}
		</Grid>
    );
};
export default SubCategory;
