import React from "react";
import { Grid } from "@mui/material";
import { MerchantInterface } from "../../store/menu/types";
import { AppBar, TabPanel } from "..";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useStyles } from "./styles";
import MenuDetails from "../MenuDetails";
interface MerchantProps {
	merchant: MerchantInterface;
}

export const Merchant = (props: MerchantProps) => {
	const { merchant } = props;
	const classes = useStyles();
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;

	return (
		<Grid>
			{merchant && merchant.menu.length > 0 && (
				<Grid
					style={{ backgroundColor: colors[0], minHeight: "100vh" }}
					className={classes.appBar}
				>
					<MenuDetails colors={colors} menu={merchant.menu} />
				</Grid>
			)}
		</Grid>
	);
};
export default Merchant;
