export const common = {
	powered: "Powered by",
	unauthorized: "Token has expired, try to login again",
	data: { empty: "No data available", try: "Please try again later" },
	back: "Back",
	invalid_credentials: "Please verify your address mail and your password",
	submit: "Submit",
	delete_title: "Would you like to remove this order",
	yes: "Yes",
	cancel: "Cancel",
	kiosk_mode_disabled:
		"Your account doesn't have any service mode. Please contact support. ",
	retry: "Retry",
	click_to_order: " Browse Our Product Lines ",
	enter_email_password: "Please  enter your email address and password",
	logout: "Logout",
	message_sent: "Message sent",
	msg: "Thanks! I have notified our Beauty consultants. Someone should be with you in the next 5 minutes.",
	application_title: "Tyme Catalog",
	name_suggestion: "suggestions",
	click_here_to : "CLICK HERE TO"
};
