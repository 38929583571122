import React from "react";
import { Redirect, Switch } from "react-router-dom";
import {
	LoginPage,
	KioskChoicePage,
	HomePage,
	ProductDetailsPage,
	ResetPasswordPage
} from "../pages";
import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";
import { PATHS } from "./paths";
import LayoutRoute from "../layouts/RouteLayout";
import ProtectedRoute from "./ProtectedRoute";
import { SQUARE_UP_STATUS } from "../utils/constants";

export const Routes = () => {
	const squareStatus = parseInt(
		localStorage.getItem(SQUARE_UP_STATUS) as string
	);

	return (
		<Switch>
			<ProtectedRoute
				path={PATHS.Home}
				exact
				component={() => <LayoutRoute component={HomePage} />}
			/>
			<ProtectedRoute
				path={PATHS.PRODUCT}
				exact
				component={() => <LayoutRoute component={ProductDetailsPage} />}
			/>

			<GuestRoute path={PATHS.LOGIN} exact component={LoginPage} />
			<GuestRoute
				path={PATHS.RESET_PASSWORD}
				exact
				component={ResetPasswordPage}
			/>
			<PrivateRoute
				path={PATHS.KIOSK_CHOICE_PATH}
				exact
				component={KioskChoicePage}
			/>
			<Redirect from="*" to={PATHS.Home} />
		</Switch>
	);
};
export default Routes;
