import React from "react";
import { Grid, Typography } from "@mui/material";
import { PlateIcon } from "..";
import { useTranslation } from "react-i18next";

interface NoDataComponentProps {}

export const NoData = (props: NoDataComponentProps) => {
	const { t } = useTranslation();

	return (
        <Grid
			container
			justifyContent="center"
			alignItems="center"
			className="no-data-container"
			direction="column"
		>
			<PlateIcon />

			<Typography className="no-data-description">
				{t("common.data.empty")}
			</Typography>
			<Typography className="no-data-description">
				{t("common.data.try")}
			</Typography>
		</Grid>
    );
};
export default NoData;
