import produce from "immer";
import {
	DeepSightActionTypes,
	DeepSightState,
	DEEP_SIGHT,
	DEEP_SIGHT_FAIL,
	DEEP_SIGHT_SUCCESS
} from "./types";

const INITIAL_STATE: DeepSightState = {
	detecting: false,
	person: null
};

export const deepSightReducer = (
	state = INITIAL_STATE,
	action: DeepSightActionTypes
): DeepSightState =>
	produce(state, draft => {
		switch (action.type) {
			case DEEP_SIGHT:
				draft.detecting = true;
				break;
			case DEEP_SIGHT_SUCCESS:
				draft.detecting = false;
				draft.person = action.payload.data;
				break;
			case DEEP_SIGHT_FAIL:
				draft.detecting = false;
				draft.person = null;
				break;
		}
	});
