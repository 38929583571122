/**
 * Custom Hooks used to handle the media
 */
/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';


//the types that return this hooks
interface UseDialogReturnType {
    open: boolean;
    setOpen: (open: boolean) => void;
    closeDialog: () => void;
    openDialog: () => void;
}

export const useDialog = (
  initValue: boolean = false
): UseDialogReturnType => {
    const [open, setOpen] = useState<boolean>(initValue);

  //Function to close the dialog
  const closeDialog = () => {
    setOpen(false);
  };
  //Function to close the dialog
  const openDialog = () => {
    setOpen(true);
  };
  return {
    open,
    setOpen,
    closeDialog,
    openDialog,
  };
};
