import React, { ComponentType } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { PATHS } from "./paths";
import { isConfigured, isLoggedIn } from "../utils/helpers";

interface IProps extends RouteProps {
	component: ComponentType<any>;
	status?: number;
	path: string;
}

function GuestRoute(props: IProps): React.ReactElement {
	const { component: Component, ...rest } = props;

	const render = (props: any) => {
		if (isConfigured()) {
			return <Redirect to={PATHS.Home} />;
		} else if (isLoggedIn()) {
			return <Redirect to={PATHS.KIOSK_CHOICE_PATH} />;
		}
		return <Component {...props} />;
	};

	return <Route {...rest} render={render} />;
}

export default GuestRoute;
