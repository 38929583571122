import React, { ComponentType, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, RouteProps } from "react-router-dom";
import { Cart, Drawer, Footer, Loader, NoData } from "../../components";
import { fetchMenu } from "../../store/menu/actions";
import { RootState } from "../../store/rootReducer";
import { useStyles } from "./styles";
import { Grid } from "@mui/material";

/**
 * Component LayoutRoute
 *
 * @component
 *
 * @example
 * return (
 *   <LayoutRoute path="/" exact component={componentName}/>
 * )
 */

export const Layout = ({ children }: any) => <div>{children}</div>;

interface IProps extends RouteProps {
	component: ComponentType<any>;
}

export const LayoutRoute = (props: IProps) => {
	const { component: Comp, ...rest } = props;
	// dispatch hooks
	const dispatch = useDispatch();

	const merchantState = useSelector((state: RootState) => state.menu);
	const classes = useStyles();

	useEffect(() => {
		const fetchData = async () => {
			await dispatch(fetchMenu());
		};
		fetchData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Route
			{...rest}
			render={matchProps => {
				return (
					<Layout>
						{merchantState.loading ? (
							<Loader />
						) : (
							<Grid>
								{!merchantState.loading &&
								merchantState.merchant?.menu ? (
									<div className={classes.pageContainer}>
										<div className={classes.container}>
											<Comp {...matchProps} />
										</div>

										<div className={classes.footer}>
											<Footer />
										</div>
									</div>
								) : (
									<div>
										<NoData />
										<div className={classes.fullFooter}>
											<Footer />
										</div>
									</div>
								)}
							</Grid>
						)}
					</Layout>
				);
			}}
		/>
	);
};
export default LayoutRoute;
