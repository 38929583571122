import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Merchant, SimpleSlider } from "../../components";
import { LOCAL_STORAGE_DEEP_SIGHT, NUMBER_OF_SECONDS_TO_SHOW_SLIDER } from "../../utils/constants";
import IdleTimer from "react-idle-timer";
import { config } from "../../config/config";
import { RootState } from "../../store/rootReducer";
import { fetchMenu } from "../../store/menu/actions";
import { REMOVE_SUGGESTION_MENU } from "../../store/menu/types";
import { detectDeepSight } from "../../store/deepSight/actions";

interface HomeProps {}

export const Home = (props: HomeProps) => {
	const merchantState = useSelector((state: RootState) => state.menu);
	const [isTimedOut, setIsTimedOut] = useState(false);
	const [idleTimer, setIdleTimer] = useState<IdleTimer | null>(null);
	const [showSlider, setShowSlider] = useState(false);
	const dispatch = useDispatch();
const deepSightState = useSelector((state: RootState) => state.deepSight);

const deepSightLoading = deepSightState.detecting;
const deepSightPerson = deepSightState.person;
	const handleOnAction = (event: any) => {
		setIsTimedOut(false);
	};

	const handleOnActive = (event: any) => {
		setIsTimedOut(false);
	};

	const handleOnIdle = (event: any) => {
		if (!isTimedOut) {
			setIsTimedOut(true);
			setShowSlider(true);
		}
	};

	const fetchData = async () => {
		await dispatch(fetchMenu());
	};

	const hideSlider = async () => {
		await fetchData();
		setShowSlider(false);
	};
	useEffect(() => {
		window.scrollTo(0, 0);
		if (merchantState.merchant && merchantState.merchant.sightcorp) {
			// first call
			dispatch(detectDeepSight());

			// a call every few seconds
			const interval = window.setInterval(() => {
				if (!deepSightLoading) {
					dispatch(detectDeepSight());
				}
			}, config.DEEP_SIGHT_TIMER);
			return () => {
				window.clearInterval(interval);
				localStorage.removeItem(LOCAL_STORAGE_DEEP_SIGHT);
				dispatch({
					type: REMOVE_SUGGESTION_MENU
				});
			};
		}
	}, []);

	useEffect(() => {
		if (deepSightPerson && deepSightPerson?.id && showSlider) {
			hideSlider();
		}
	}, [deepSightPerson]);
	return (
		<div>
			<IdleTimer
				ref={(ref: any) => {
					setIdleTimer(ref);
				}}
				timeout={NUMBER_OF_SECONDS_TO_SHOW_SLIDER * 1000}
				onActive={handleOnActive}
				onIdle={handleOnIdle}
				onAction={handleOnAction}
				debounce={NUMBER_OF_SECONDS_TO_SHOW_SLIDER * 1000}
			/>
			{showSlider ? (
				<SimpleSlider hideSlider={hideSlider} />
			) : (
				merchantState.merchant?.menu && (
					<Merchant merchant={merchantState.merchant} />
				)
			)}
		</div>
	);
};
export default Home;
