import { ProductModal } from "../store/shoopingCart/models";
import {
	LOCAL_STORAGE_TOKEN,
	LOCAL_STORAGE_MODE,
	LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED,
	LOCAL_STORAGE_ORDER_ID,
	LOCAL_STORAGE_SELECTED_MODE,
	FEMALE_GENDER,
	MALE_GENDER
} from "./constants";

/**
 * Confirms if there is a connected user or not
 *
 * @returns {boolean}
 */
export const isLoggedIn = () => {
	return localStorage.getItem(LOCAL_STORAGE_TOKEN) ? true : false;
};

/**
 *
 */
export const isConfigured = () => {
	return localStorage.getItem(LOCAL_STORAGE_TOKEN) &&
		localStorage.getItem(LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED) &&
		localStorage.getItem(LOCAL_STORAGE_MODE)
		? true
		: false;
};

/**
 *
 */
export const orderSubmitted = () => {
	return localStorage.getItem(LOCAL_STORAGE_ORDER_ID) ? true : false;
};

/**
 * Returns the first error from the ws response
 *
 * @param {any} e
 *
 * @returns {string}
 */
export const getErrorMessage = (e: any): string => {
	const defaultError = "error.unknow";
	let message = "";
	if (typeof e === "object" && e.message) {
		message = e;
	} else if (typeof e === "string") {
		message = e || defaultError;
	} else if (e && e && e.data) {
		let eData = e.data;
		if (eData) {
			message = eData.message;
		}
	} else {
		message = "error.network";
	}
	return message || defaultError;
};

export const isEmail = (email: string): boolean => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const findByCode = (array: Array<any>, code: string) => {
	const item = array.filter((item, index) => item.code === code);
	if (item) return item[0];
	return null;
};

export const CheckIsGlobal = () => {
	return localStorage.getItem(LOCAL_STORAGE_SELECTED_MODE) === "1"
		? true
		: false;
};
//function to check if one of the selected modifiers has a forced tax === 0
export const hasForcedTax = (
	product: ProductModal,
	modifiersSelected: number[]
) => {
	let hasForcedTax = false;
	product.groups.forEach(element => {
		element.modifiers.forEach(modifier => {
			//forEach check if modifier exist in modifierSelected and the modifier has force_tax === 0
			if (
				modifiersSelected.includes(modifier.id) &&
				modifier.pivot.force_tax === 1
			) {
				hasForcedTax = true;
				return;
			}
		});
	});
	return hasForcedTax;
};

/**
 *
 * @param price
 * @returns
 */
export const getPriceFormat = (price: number) => {
	return price.toFixed(2);
};

/**
 *
 * @param pricef
 * @return String
 */
export const truncate2NumbersWithouRounding = (price: number) => {
	return price.toString().slice(0, price.toString().indexOf(".") + 3);
};

export const removeMaskElements = function (input: string) {
	let elemntsToRemove = ["_", "-"];
	if (input) {
		// @ts-ignore
		return [...input]
			.filter(item => !elemntsToRemove.includes(item))
			.join("");
	}
	return "";
};

/**
 * get the gender by number
 *
 * @param gender
 * @returns number
 */
export const getPersonGender = (gender: number) => {
	if (gender > 0 && gender <= 100) {
		return FEMALE_GENDER;
	} else if (gender < 0 && gender >= -100) {
		return MALE_GENDER;
	}
	return -1;
};

/**
 * verify id the ages interval is different
 *
 * @param firstAge
 * @param secondAge
 * @returns boolean
 */
export const sameAgeRange = (firstAge: number, secondAge: number) => {
	if (!secondAge) {
		return false;
	}
	if (Math.abs(firstAge - secondAge) > 10) {
		return false;
	}
	return true;
};
