import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import {
	Grid,
	IconButton,
	Input,
	Typography,
	CircularProgress
} from "@mui/material";
import { useStyles } from "./styles";
import { logout } from "../../store/authentification/actions";
import { isEmail } from "../../utils/helpers";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { button_type, IS_FOCUSED_COLOR } from "../../utils/constants";

interface LogoutDialogProps {
	open: boolean;
	handleClose: () => void;
	onBackdropClick: () => void;
}

interface InputProps<T> {
	value: T;
	error: string | null;
}

const LogoutDialog: React.FC<LogoutDialogProps> = ({
	handleClose,
	open,
	onBackdropClick
}) => {
	const loginRef = useRef<HTMLInputElement | null>(null);
	const passwordRef = useRef<HTMLInputElement | null>(null);
	const [layout, setLayout] = useState("default");
	const ref = useRef<HTMLDivElement | null>(null);
	const keyboard = useRef<typeof Keyboard | null>(null);
	const wishInput = useRef<number>(-1);
	const [showKeyboard, setShowKeyboard] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const classes = useStyles({ colors });
	const logoutState = useSelector((state: RootState) => state.auth.logout);
	const { loader } = logoutState;
	const [email, setEmail] = React.useState<InputProps<string>>({
		value: "",
		error: null
	});

	const [password, setPassword] = React.useState<InputProps<string>>({
		value: "",
		error: null
	});

	const handleChangeEmail = (e: any) => {
		setEmail({
			...email,
			value: e.target.value,
			error: null
		});
	};

	const handleChangePassword = (e: any) => {
		setPassword({
			...password,
			value: e.target.value,
			error: null
		});
	};

	const clearInputs = () => {
		handleClose();
		setEmail({
			...email,
			value: "",
			error: null
		});
		setPassword({
			...password,
			value: "",
			error: null
		});
	};

	const handleSubmit = () => {
		if (email.value === "")
			setEmail({ ...email, error: t("validations.required") });
		if (email.value !== "" && !isEmail(email.value))
			setEmail({ ...email, error: t("validations.email") });
		if (password.value === "")
			setPassword({ ...password, error: t("validations.required") });
		if (
			isEmail(email.value) &&
			email.value !== "" &&
			password.value !== ""
		) {
			dispatch(
				logout({
					email: email.value,
					password: password.value
				})
			);
		}
	};
	const handleShift = () => {
		const newLayoutName =
			layout === button_type.DEFAULT
				? button_type.SHIFT
				: button_type.DEFAULT;
		setLayout(newLayoutName);
	};
	const onKeyPress = (button: any) => {
		if (button === "{bksp}") {
		}
		/**
		 * If you want to handle the shift and caps lock buttons
		 */
		if (button === "{shift}" || button === "{lock}") handleShift();
	};
	const onChange = (input: any) => {
		//@ts-ignore
		switch (wishInput.current) {
			case 0:
				setEmail({ ...email, value: input });
				break;
			case 1:
				setPassword({ ...password, value: input });
				break;
			default:
				break;
		}
	};
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			//@ts-ignore
			if (
				ref.current &&
				!ref.current.contains(event.target as Node) &&
				event.target.tagName.toUpperCase() !== "INPUT" &&
				event.target.tagName.toUpperCase() !== "SPAN"
			) {
				//@ts-ignore
				setShowKeyboard(false);
				wishInput.current = -1;
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);

	const onFocusEmail = (e: any) => {
		e.target.blur();
		setEmail({ ...email });
		if (keyboard.current !== null) {
			//@ts-ignore
			keyboard.current.setInput(email.value);
		}
		wishInput.current = 0;
		setShowKeyboard(true);
	};
	const onFocusPassword = (e: any) => {
		e.target.blur();
		setPassword({ ...password });
		if (keyboard.current !== null) {
			//@ts-ignore
			keyboard.current.setInput(password.value);
		}
		wishInput.current = 1;
		setShowKeyboard(true);
	};
	return (
		<div>
			<Dialog
				style={{ marginBottom: showKeyboard ? "35vh" : 0 }}
				open={open}
				onClose={(event, reason) => {
					if (reason !== "backdropClick") {
						handleClose();
					}
				}}
				aria-labelledby="form-dialog-title"
				fullWidth
				onBackdropClick={onBackdropClick}
			>
				<DialogTitle
					className={classes.dialogTitleStyle}
					id="form-dialog-title"
				>
					<Grid container>
						<Grid item xs={11}>
							<Grid container justifyContent="center">
								<Typography
									className={classes.cancelColor}
									style={{ fontSize: 18 }}
								>
									{t("common.logout")}
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={1}>
							<Grid container justifyContent="flex-end">
								<IconButton size="small" onClick={clearInputs}>
									<CancelIcon
										className={classes.cancelColor}
									/>
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent className={classes.dialogTitleStyle}>
					<Typography className={classes.cancelColor}>
						{t("login.email")}
					</Typography>
					<Input
						style={{
							border:
								wishInput.current === 0
									? `3px solid ${IS_FOCUSED_COLOR}`
									: undefined
						}}
						ref={loginRef}
						onFocus={onFocusEmail}
						onChange={handleChangeEmail}
						className={classes.inputStyle}
						disableUnderline={true}
						value={email.value}
					/>
					<div className={classes.errorContainerStyle}>
						{email.error}
					</div>
					<div style={{ height: 4 }}></div>
					<Typography className={classes.cancelColor}>
						{t("login.password")}
					</Typography>
					<Input
						style={{
							border:
								wishInput.current === 1
									? `3px solid ${IS_FOCUSED_COLOR}`
									: undefined
						}}
						ref={passwordRef}
						onFocus={onFocusPassword}
						type="password"
						onChange={handleChangePassword}
						className={classes.inputStyle}
						disableUnderline={true}
						value={password.value}
					/>
					<div className={classes.errorContainerStyle}>
						{password.error}
					</div>
					<div style={{ height: 15 }}></div>
				</DialogContent>
				<DialogActions className={classes.dialogActionStyle}>
					<Button
						fullWidth
						className={classes.btnStyle}
						onClick={handleSubmit}
						color="primary"
					>
						{!loader ? (
							<Typography style={{ fontSize: 18 }}>
								{t("common.submit").toUpperCase()}
							</Typography>
						) : (
							<CircularProgress
								style={{ color: colors[1] }}
								color="secondary"
								size={30}
							/>
						)}
					</Button>
				</DialogActions>
				{showKeyboard && (
					<div ref={ref} className={"keyboardContainer"}>
						<Keyboard
							keyboardRef={(r: any) => (keyboard.current = r)}
							layoutName={layout}
							onChange={onChange}
							onKeyPress={onKeyPress}
						/>
					</div>
				)}
			</Dialog>
		</div>
	);
};
export default React.memo(LogoutDialog);
