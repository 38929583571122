import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppBar, TabPanel } from "..";
import { getVal, getValue } from "../../store/menu/actions";
import { MenuActionTypes, MenuInterface } from "../../store/menu/types";
import { RootState } from "../../store/rootReducer";
import { SUGGESTION_CATEGORY } from "../../utils/constants";
import AppBarSubCategoryCompoent from "../AppBar/AppBarSubCategoryCompoent";

interface MenuDetailsProps {
	menu: MenuInterface[];
	colors: string[];
}

export const MenuDetails = (props: MenuDetailsProps) => {
	const { menu, colors } = props;
	const dispatch = useDispatch<ThunkDispatch<RootState, undefined, MenuActionTypes>>();
	const { valueCategory, valSubCategory } = useSelector((state: RootState) => state.menu);
	const [value, setValue] = React.useState<number>(valueCategory);
	const [val, setVal] = React.useState<number>(valSubCategory);
  const [nameSubCategory, setNameSubCategory] = React.useState<string>('');
	const [ifSuggestion, setIfSuggestion] = React.useState<boolean>(false);
  const deepSightState = useSelector((state: RootState) => state.deepSight);

	const deepSightPerson = deepSightState.person;
	const handleChange = (newValue: number) => {
		setValue(newValue);
		setVal(0);
	};
  const handleChangeSubCategory = (newValue: number) => {
  setVal(newValue);
		};
	useEffect(() => {
		menu[value].name == SUGGESTION_CATEGORY && deepSightPerson != null
			? setIfSuggestion(false)
			: setIfSuggestion(true);
	}, [value])

	useEffect(() => {
		if (deepSightPerson && deepSightPerson?.id) {
			setIfSuggestion(false);
		} else {
			setIfSuggestion(true);
		}
	}, [deepSightPerson]);

		useEffect(() => {
			dispatch(getValue(value));
			dispatch(getVal(val));
			
		}, [value, val]);
	
	useEffect(() => {
		setValue(valueCategory);
		setVal(valSubCategory)
	}, [valueCategory, valSubCategory]);
	return (
		<>
			<AppBar
				menu={menu}
				changeTab={handleChange}
				value={value}
				colors={colors}
			/>
			{ifSuggestion &&
				(<AppBarSubCategoryCompoent
				menu={menu}
				value={value}
				colors={colors}
				handleChangeSubCategory={handleChangeSubCategory}
				val={val}
				nameSubCategory={nameSubCategory}
				setNameSubCategory={setNameSubCategory}
			/>)
		}
		
			<TabPanel
				menu={menu}
				subCateg={val}
				value={value}
				colors={colors}
			/>
		</>
	);
};
export default MenuDetails;
