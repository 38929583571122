import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
	root: {
		padding: 20,
		height: "100%",
		paddingTop: 100,
		width: "100vw",
		columnGap: "2%",
		[theme.breakpoints.down("md")]: {
			paddingRight: 70
		}
	},
	imgContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		textAlign: "center",
		width: "100%",
		height: "100%"
	},
	grid: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		textAlign: "center",
		width: "100%"
	},
	ImageChoice: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		gap: "20px",
		marginLeft: "6%",
		width: "100%",
		cursor: "pointer",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			marginLeft: "0%"
		}
	},
	ProductDetailPanel: {
		marginLeft: "8%",
		width: "100vw"
	},
	relatedProducts: {
		[theme.breakpoints.down("md")]: {
			paddingRight: 70
		}
	},
	relatedP: {
		display: "flex",
		flexDirection: "row",
		columnGap: "5%",
		bottom: "0px",
		marginBottom: "0px",
		marginTop: "50px"
	},
	media: {
		width: "300px !important",
		height: "308px !important",
		borderRadius: 10,
		maxWidth: "300px !important",
		maxHeight: "308px !important",
		cursor: "pointer",
		marginTop: "10%",
		objectFit: "contain"
	},
	img: {
		width: 260,
		height: "100%",
		borderRadius: 10,
		maxWidth: "100%",
		cursor: "pointer",
		marginTop: "10%",
		objectFit: "contain"
	},
	card: {
		display: "flex",
		textAlign: "center",
		justifyContent: "center",
		width: "100%",
		maxHeight: "500px"
	},

	titleContainer: {
		minHeight: 150,
		width: "100%"
	},
	titleAndPrice: {
		height: "auto",
		width: "100%",
		display: "flex",
		// alignItems: "center",
		[theme.breakpoints.down("lg")]: {
			flexDirection: "column",
			alignItems: "left"
		}
	},
	btn: {
		height: 55,
		width: "200px",
		borderRadius: "30px",
		fontSize: "12px",
		padding: "6px 16px",
		fontWeight: "500",
		[theme.breakpoints.down("lg")]: {
			fontSize: "10px",
			width: "150px"
		},
		[theme.breakpoints.down("lg")]: {
			fontSize: "10px",
			width: " 150px"
		}
	},
	title: {
		textAlign: "left",
		marginLeft: 8,
		height: "100%",
		fontWeight: "bold",
		fontSize: "25px",
		maxWidth: "100% !important"
	},
	price: {
		textAlign: "right",
		marginRight: 8,
		height: "100%",
		fontWeight: "bold"
	},
	descrption: {
		textAlign: "left",
		paddingTop: 10,
		paddingLeft: 8
	},
	box: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
		height: "80%",
		background: "white",
		p: 4,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		padding: "5px !important",
	},
	boxContent: {
		overflowY: "scroll"
	},
	closeIcon: {
		height: "8%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "end",
		cursor: "pointer"
	},
	numberReviews: {
		marginLeft: "10px",
		fontWeight: "bold",
		cursor: "pointer"
	},
	titleMainContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "baseline"
	},
	qunatityContainer: {
		width: "100%",
		marginTop: 20,
		display: "flex",
		height: 60
	},
	qunatityButtonsContainer: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		padding: 10
	},
	detailsContainer: {
		width: "100%",
		marginTop: 20,
		display: "flex"
	},
	details: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: 500
	},
	modifierGroups: {
		height: 200,
		paddingLeft: "3%",
		width: "90%"
	},
	btnContainer: {
		height: 100,
		backgroundColor: "white",
		width: "90%",
		justifyContent: "center",
		alignItems: "center",
		display: "flex"
	},
	footer: {
		width: "100%",
		marginTop: 20,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column"
	},
	addToOrderBtnContainer: {
		width: "100%",
		marginTop: 20,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column"
	},
	modifierGroup: {
		fontSize: "18px",
		fontWeight: "bold"
	},
	modContainer: {
		paddingLeft: "8%",
		textAlign: "left",
		width: "100%",
		marginTop: "5%"
	},
	span: {
		fontWeight: "bold",
		marginBottom: "2%"
	},
	modifiers: {},
	modifier: {
		display: "flex",
		width: "100%"
	},
	modifierGroupContainer: {},
	checkboxContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		marginRight: 22
	},
	modifierName: {
		display: "flex",
		alignItems: "center"
	},
	category: {
		fontSize: 20,
		marginLeft: 15
	},
	appBar: {
		left: 0,
		height: 66,
		display: "flex",
		justifyContent: "center",
		[theme.breakpoints.up("md")]: {
			width: "100vw"
		},
		"& p": {
			padding: "10px 0px",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden"
		}
	}
}));
